import { DataResponse } from "../types/Api"

export const filterTokens = <T>(data: Record<string, T>, tokens: string[]): Record<string, T> => {
	if (tokens.length === 0) return data;
	return filterObject(data, ([key, value]) => {
		return tokens.includes(key)
	})
}

export const getDataTotal = (data: Record<string, {[key: string]: any}[]>, key: string): number => {
	let total = 0
	Object.entries(data).forEach(([token, dataValues]) => {
		if (dataValues && Array.isArray(dataValues)) {
			dataValues.forEach((data, i) => {
				if (key === "__count") return total += 1
				if (!data[key]) return;
				total += Number.parseFloat(data[key])
			})
		}
	})
	return total;
}

export const getDataTotals = (data: Record<string, {[key: string]: any}[]>, keys: string[]): Record<string, number> => {
	let totals: Record<string, number> = {}
	Object.entries(data).forEach(([token, dataValues]) => {
		if (dataValues && Array.isArray(dataValues)) {
			dataValues.forEach((data, i) => {
				keys.forEach((key) => {
					if (totals[key] === undefined) totals[key] = 0
					if (!data[key]) return;
					totals[key] += Number.parseFloat(data[key]) || 0
				})
			})
		}
	})
	return totals;
}

export const getValuesFromPeriod = <T>(arr: T[], period: [number, number]): T[] => {
	let min = period[0]
	let max = period[1]
	if (max === -1) max = arr.length;
	return [...arr].splice(min, max)
}

export const getDataPeriod = (
data: Record<string, Record<string, any>[]>,
period: [number, number],
sortKey: string): Record<string, Record<string, any>[]> => {
	let newData: Record<string, Record<string, any>[]> = {}
	Object.entries(data).forEach(([key, value]: [string, Record<string, any>[]]) => {
		newData[key] = getValuesFromPeriod<Record<string, any>>([...value.sort((a, b) => b[sortKey] - a[sortKey])], period)
	})
	return newData
}

export const getDataPeriodTotals = (data: Record<string, {[key: string]: any}[]>, keys: string[], periods: [number, number][]): Record<string, number>[] => {
	let totals: Record<string, number>[] = []
	periods.forEach((period, periodIndex) => {
		totals[periodIndex] = {};
		let currentTotal = totals[periodIndex]
		Object.entries(data).forEach(([token, dataValues]) => {
			if (dataValues && Array.isArray(dataValues)) {
				getValuesFromPeriod(dataValues, period).forEach((data) => {
					keys.forEach((key) => {
						if (!currentTotal[key]) currentTotal[key] = 0
						currentTotal[key] += Number.parseFloat(data[key] || 0)
					})
				})
			}
		})
	})
	return totals;
}

export const getDataTotalFromExtracted = (data: Record<string, any>[], key: string): number => {
	let total = 0;
	data.forEach((dataItem) => {
		let parsed = Number.parseFloat(dataItem[key])
		if (Number.isNaN(parsed)) {
			return;
		}
		total += parsed;
	})
	return total
}

export const filterObject = <T>(obj: Record<string, T>, filterFunction: (entry: [string, any]) => boolean): Record<string, T> => {
	let newObj: Record<string, T> = {}
	Object.entries(obj).forEach((entry) => {
		if (filterFunction(entry)) newObj[entry[0]] = entry[1]
	})
	return newObj
}

export const filterAndCombine = (
	data: DataResponse<Record<string, any>>,
	selected: Record<string, string[]>
) => {
	let newData: Record<string, any> = {}
	Object.entries(data).forEach(([category, dataValues], i) => {
		Object.entries(filterTokens(dataValues, selected[category])).forEach(([token, dataItems]) => {
			let key = formatCategory(token, category)
			newData[key] = dataItems
		})
	})
	return newData
}

export const formatCategory = (str: string, category: string): string => {
	if (category === "dashboards") category = ""
	category = category.replace(/s$/, "")
	return category ? `${str} ${category}` : str
}

export const getCombinedTotalKeys = (
	keyArrs: string[][],
): string[] => {
	const categories = ["", "swap"]
	let total: string[] = []
	keyArrs.forEach((keyArr, i) => {
		let category = categories[i]
		keyArr.forEach((key) => total.push(formatCategory(key, category)))
	})
	return total;
}

export const extractChartData = (
	dataObj: Record<string, Record<string, any>[]> | null | undefined,
	valueKeys: string | string[],
	staticKeys: string | string[]
): Record<string, any>[] => {
	let valueKeyArr: string[] = []
	if (typeof(valueKeys) === "string") valueKeyArr = [valueKeys]
	else valueKeyArr = valueKeys

	let staticKeyArr: string[] = []
	if (typeof(staticKeys) === "string") staticKeyArr = [staticKeys]
	else staticKeyArr = staticKeys

	let newData: {
		[key: string]: string | number
	}[] = [];

	Object.entries(dataObj || {}).forEach(([token, values], i) => {
		valueKeyArr.forEach((valueKey, i) => {
			values.forEach((value: any) => {
				let foundDate: Record<string, any> = newData.find((data) => data[staticKeyArr[0]] === value[staticKeyArr[0]]) || {}
				if (Object.entries(foundDate).length === 0) {
					staticKeyArr.forEach((staticKey) => {
						foundDate[staticKey] = value[staticKey]
					})
					newData.push(foundDate)
				}
				let key = token
				if (!foundDate[key]) foundDate[key] = []
				if (!value[valueKey]) return;
				foundDate[key][i] = (foundDate[key][i] || 0) + Number.parseFloat(value[valueKey])
			}, 0)
		})
	})

	return newData.sort(
		(a, b) =>
			new Date(a[staticKeyArr[0]] || "").getTime() -
			new Date(b[staticKeyArr[0]] || "").getTime()
	)
}

export const extractCombinedChartData = (
	dataObjs: Record<string, Record<string, any>[]>[],
	valueKeys: string[][],
	staticKeys: string | string[],
): Record<string, any>[] => {
	let staticKeyArr: string[] = []
	if (typeof(staticKeys) === "string") staticKeyArr = [staticKeys]
	else staticKeyArr = staticKeys
	
	let newData: Record<string, any>[] = []
	dataObjs.forEach((dataObj, dataObjIndex) => {
		let filtered = dataObj
		Object.entries(filtered).forEach(([tokenName, dataValues]: [string, Record<string, any>[]], dataValueIndex) => {
			dataValues.forEach((dataItem: any) => {
				let foundItem = newData.find((currItem, newDataIndex) => staticKeyArr[0] === "token" ? dataValueIndex === newDataIndex : currItem[staticKeyArr[0]] === dataItem[staticKeyArr[0]]) || {}
				if (Object.entries(foundItem).length === 0) {
					if (staticKeyArr[0] === "token") foundItem.token = tokenName
					else staticKeyArr.forEach((staticKey) => {
						foundItem[staticKey] = dataItem[staticKey]
					})
					newData.push(foundItem)
				}
				valueKeys[dataObjIndex].forEach((valueKey) => {
					foundItem[valueKey] = (foundItem[valueKey] || 0) + dataItem[valueKey]
				})
			})
		})
	})
	return newData;
}

export const extractSortedChartData = (
	dataObj: Record<string, any[]> | null | undefined,
	valueKeys: string | string[],
	staticKey: string,
	sortables: Record<string, boolean>,
	reverseOrder?: boolean,
	selectedDashboards?: string[]
)  => {
	let valueKeyArr: string[] = []
	if (typeof(valueKeys) === "string") valueKeyArr = [valueKeys]
	else valueKeyArr = valueKeys

	let newData: {
		[key: string]: string | number
	}[] = [];

	let filtered = selectedDashboards ? filterTokens(dataObj || {}, selectedDashboards) : dataObj || {}

	Object.entries(filtered).forEach(([tokenName, values], i) => {
		values.forEach((val: any) => {
			let objValue = newData.find((newDataItem, i2) => staticKey === "token" ? (i === i2) : (newDataItem[staticKey] === val[staticKey])) || {}
			if (Object.entries(objValue).length === 0) {
				objValue = {[staticKey]: staticKey === "token" ? tokenName : val[staticKey]}
				newData.push(objValue)
			}
			valueKeyArr.forEach((valueKey) => {
				let parsed = Number.parseFloat(val[valueKey])
				if (Number.isNaN(parsed)) {
					return;
				};
				objValue[valueKey] = (objValue[valueKey] as number || 0) + parsed
			})
		})
	})
	
	let sorted: {
		[key: string]: {
			[key: string]: string | number
		}[]
	} = {}
	Object.entries(sortables).forEach(([key, value]: [string, boolean]) => {
		key = key as keyof typeof sortables
		if (sortables[key] === undefined) return
		sorted[key] = [...newData].sort((a, b) => ((b[key] as number) - (a[key] as number)) * (reverseOrder ? -1 : 1))
	})
	return sorted
}

export const extractCombinedSortedChartData = (
	dataObjs: DataResponse<Record<string, any[]>>[],
	valueKeys: string[][],
	staticKey: string,
	sortables: Record<string, boolean>,
	reverseOrder: boolean = false,
	selected?: Record<string, string[]>
)  => {
	let newData: {
		[key: string]: string | number
	}[] = [];

	dataObjs.forEach((dataObj, dataObjIndex) => {
		let filtered = filterAndCombine(dataObj || {}, selected || {})
		Object.entries(filtered).forEach(([tokenName, values], i) => {
			values.forEach((val: any) => {
				let objValue = newData.find((newDataItem, i2) => staticKey === "token" ? (i === i2) : (newDataItem[staticKey] === val[staticKey])) || {}
				if (Object.entries(objValue).length === 0) {
					objValue = {[staticKey]: staticKey === "token" ? tokenName : val[staticKey]}
					newData.push(objValue)
				}
				valueKeys[dataObjIndex].forEach((valueKey) => {
					if (Number.isNaN(Number.parseFloat(val[valueKey]))) return;
					objValue[valueKey] = (objValue[valueKey] as number || 0) + Number.parseFloat(val[valueKey])
				})
			})
		})		
	})
	
	let sorted: {
		[key: string]: {
			[key: string]: string | number
		}[]
	} = {}


	Object.entries(sortables).forEach(([key, value]: [string, boolean]) => {
		key = key as keyof typeof sortables
		if (sortables[key] === undefined) return
		sorted[key] = [...newData].filter((token) => {
			return token[key] !== undefined
		}).sort((a, b) => {
			return ((b[key] as number) - (a[key] as number)) * (reverseOrder ? -1 : 1)
		})
	})
	return sorted
}

export const extractTableData = (
	dataObj: Record<string, Record<string, any>[]>,
	valueKeys: string | string[],
	staticKey: string,
) => {
	let valueKeyArr: string[] = []
	if (typeof(valueKeys) === "string") valueKeyArr = [valueKeys]
	else valueKeyArr = valueKeys

	let newData: {
		[key: string]: string | number
	}[] = [];

	Object.entries(dataObj).forEach(([token, tokenData]) => {
		tokenData.forEach((tokenDataItem) => {
			let foundData = newData.find((newDataItem) => newDataItem[staticKey] === tokenDataItem[staticKey]) || {}
			if (Object.entries(foundData).length === 0) {
				foundData = {[staticKey]: tokenDataItem[staticKey]}
				newData.push(foundData)
			}
			valueKeyArr.forEach((valueKey) => {
				if (valueKey === staticKey) return;
				foundData[valueKey] = (foundData[valueKey] as number || 0) + (Number.parseFloat(tokenDataItem[valueKey] as string || "0") || 0)
			})
		})
	})
	
	return newData.sort((a, b) => new Date(b[staticKey] || "").getTime() - new Date(a[staticKey || ""]).getTime())
}