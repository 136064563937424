import { AlertContextWrapper } from "./AlertContext";
import { AuthContextWrapper } from "./AuthContext";
import { CountriesContextWrapper } from "./CountriesContext";
import { CurrenciesContextWrapper } from "./CurrenciesContext";
import { DashboardsContextWrapper } from "./DashboardsContext";
import { DepositsContextWrapper } from "./DepositsContext";
import { FilterContextWrapper } from "./FilterContext";
import { NavigationContextWrapper } from "./NavigationContext";
import { RegistrationsContextWrapper } from "./RegistrationsContext";
import { SummaryContextWrapper } from "./SummaryContext";
import { SwapContextWrapper } from "./SwapContext";
import { ThemeContextWrapper } from "./ThemeContext";
import { TransactionsContextWrapper } from "./TransactionsContext";

export const GlobalContextWrapper: React.FC = ({ children }) => {
	return (
		<ThemeContextWrapper>
			 <AlertContextWrapper>
				<NavigationContextWrapper>
					<AuthContextWrapper>
						<DashboardsContextWrapper>
							<SwapContextWrapper>
								<FilterContextWrapper>
									<RegistrationsContextWrapper>
										<TransactionsContextWrapper>
											<SummaryContextWrapper>
												<CountriesContextWrapper>
													<CurrenciesContextWrapper>
														<DepositsContextWrapper>
															{children}
														</DepositsContextWrapper>
													</CurrenciesContextWrapper>
												</CountriesContextWrapper>
											</SummaryContextWrapper>
										</TransactionsContextWrapper>
									</RegistrationsContextWrapper>
								</FilterContextWrapper>
							</SwapContextWrapper>
						</DashboardsContextWrapper>
					</AuthContextWrapper>
				</NavigationContextWrapper>
			</AlertContextWrapper>
		</ThemeContextWrapper>
	)
}