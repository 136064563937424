export interface ThemeColour {
	light: string;
	main: string;
	dark: string;
	gradient?: string;
	contrastText: string;
}

export interface ThemeBreakpointObject {
	max?: string;
	value?: string;
}

export type ThemeBreakpoint = ThemeBreakpointObject | string;

export type ThemeBreakpoints = {
	xs: ThemeBreakpoint;
	sm: ThemeBreakpoint;
	md: ThemeBreakpoint;
	lg: ThemeBreakpoint;
	xl: ThemeBreakpoint;
	"2xl": ThemeBreakpoint;
	[key: string]: ThemeBreakpoint;
};

export interface Theme {
	key: string;
	label: string;
	type: "light" | "dark"
	radius?: {
		paper?: string;
		button?: string;
		item?: string;
		dropdown?: string;
		card?: string
	};
	breakpoints?: ThemeBreakpoints;
	primary?: ThemeColour;
	secondary?: ThemeColour;
	anim?: {
		short?: number;
		norm?: number;
		long?: number;
		spinner?: number;
		spinnerEase?: string;
		ease?: string;
	};
	font?: {
		primary?: string;
		secondary?: string;
		title?: string;
		heading?: string;
		display?: string;
		subheading?: string;
		size?: number;
	};
	link?: {
		color?: string;
	};
	zindex?: {
		navbar?: number;
		dialog?: number;
		snackbar?: number;
		tooltip?: number;
	};
	text?: {
		primary: string;
		primaryMuted: string;
		secondary: string;
		tertiary: string;
		muted?: string;
		subheading?: string
	};
	money?: string;
	favourite?: string;
	divider?: string;
	background?: {
		paperLight?: string;
		paper?: string;
		paperDark?: string;
		navbar?: string;
		contrast?: string;
		default?: string;
		base?: string
	};
	success?: {
		contrastText?: string;
		light?: string;
		main?: string;
		dark?: string;
	};
	error?: {
		contrastText?: string;
		light?: string;
		main?: string;
		dark?: string;
	};
	info?: {
		contrastText?: string;
		light?: string;
		main?: string;
		dark?: string;
	};
	warning?: {
		contrastText?: string;
		light?: string;
		main?: string;
		dark?: string;
	};
	action?: {
		disabled?: string;
		ripple?: string;
		hover?: string;
		loading?: string;
		redacted?: string;
		inactive?: string
	};
	border?: {
		main?: string;
		subtle?: string;
		highlight?: string;
		contrast?: string;
		primary?: string;
	};
	elevations?: Array<string>;
	shadows?: {
		card?: string
	}
}

const getShadowCol = (opacity: number) => {
	return `rgba(0,0,0,${opacity})`;
};

const baseTheme: Theme = {
	key: "base",
	label: "Base",
	type: "dark",
	radius: {
		paper: "0.5rem",
		button: "0.5rem",
		item: "1rem",
		dropdown: "0.25rem",
		card: "0.25rem"
	},
	breakpoints: {
		xs: { max: "639px" },
		sm: "640px",
		md: "768px",
		lg: "1024px",
		xl: "1280px",
		"2xl": "1536px",
	},
	primary: {
		light: "#00B4DB",
		main: "#0090b0",
		dark: "#0083B0",
		contrastText: "#000",
	},
	secondary: {
		light: "#d46c6e",
		main: "#d45558",
		dark: "#b33235",
		contrastText: "#fff"
	},
	anim: {
		short: 100,
		norm: 150,
		long: 300,
		spinner: 1200,
		spinnerEase: "cubic-bezier(0.645, 0.045, 0.355, 1.000)",
		ease: "ease-out",
	},
	font: {
		primary: "Gilroy, sans-serif",
		secondary: "IBM Plex Mono, monospace",
		display: "Furore, sans-serif",
		size: 16,
	},
	text: {
		primary: "#fff",
		secondary: "#ddd",
		tertiary: "#7873A6",
		muted: "rgba(255,255,255,0.7)",
		primaryMuted: "rgba(16, 13, 25,0.5)"
	},
	link: {
		color: "#a4c7e5",
	},
	divider: "rgba(255,255,255,0.2)",	
	background: {
		paperLight: "#2e1549",
		paper: "#1D0D35",
		paperDark: "#11081f",
		contrast: "#1c152f",
		default: "#151120",
		base: "#ccc"
	},
	success: {
		contrastText: "#000",
		light: "#C1E9CE",
		main: "#45AE61",
		dark: "#357a38",
	},
	error: {
		contrastText: "#ffffff",
		light: "#F9E3E3",
		main: "#F94640",
		dark: "#9e0b0b",
	},
	info: {
		contrastText: "#ffffff",
		light: "#CCEAFE",
		main: "#2C6CEC",
		dark: "#115293",
	},
	warning: {
		contrastText: "#ffffff",
		light: "#FAE6CB",
		main: "#F3AC3B",
		dark: "#b27000",
	},
	action: {
		disabled: "#9D9D9D",
		hover: "rgba(255,255,255, 0.1)",
		loading: "#777",
		redacted: "#777",
		inactive: "#aaa",
	},
	border: {
		main: "rgba(255,255,255,0.1)",
		subtle: "rgba(255,255,255,0.1)",
		contrast: "#2e9cfc",
		primary: "#57EDB7",
	},
	elevations: [
		"none",
		`0px 2px 1px -1px ${getShadowCol(0.2)},0px 1px 1px 0px ${getShadowCol(
			0.14
		)},0px 1px 3px 0px ${getShadowCol(0.12)}`,
		`0px 3px 1px -2px ${getShadowCol(0.2)},0px 2px 2px 0px ${getShadowCol(
			0.14
		)},0px 1px 5px 0px ${getShadowCol(0.12)}`,
		`0px 3px 3px -2px ${getShadowCol(0.2)},0px 3px 4px 0px ${getShadowCol(
			0.14
		)},0px 1px 8px 0px ${getShadowCol(0.12)}`,
		`0px 2px 4px -1px ${getShadowCol(0.2)},0px 4px 5px 0px ${getShadowCol(
			0.14
		)},0px 1px 10px 0px ${getShadowCol(0.12)}`,
		`0px 3px 5px -1px ${getShadowCol(0.2)},0px 5px 8px 0px ${getShadowCol(
			0.14
		)},0px 1px 14px 0px ${getShadowCol(0.12)}`,
		`0px 3px 5px -1px ${getShadowCol(0.2)},0px 6px 10px 0px ${getShadowCol(
			0.14
		)},0px 1px 18px 0px ${getShadowCol(0.12)}`,
		`0px 4px 5px -2px ${getShadowCol(0.2)},0px 7px 10px 1px ${getShadowCol(
			0.14
		)},0px 2px 16px 1px ${getShadowCol(0.12)}`,
		`0px 5px 5px -3px ${getShadowCol(0.2)},0px 8px 10px 1px ${getShadowCol(
			0.14
		)},0px 3px 14px 2px ${getShadowCol(0.12)}`,
		`0px 5px 6px -3px ${getShadowCol(0.2)},0px 9px 12px 1px ${getShadowCol(
			0.14
		)},0px 3px 16px 2px ${getShadowCol(0.12)}`,
		`0px 6px 6px -3px ${getShadowCol(0.2)},0px 10px 14px 1px ${getShadowCol(
			0.14
		)},0px 4px 18px 3px ${getShadowCol(0.12)}`,
	],
	shadows: {
		card: "rgba(0, 0, 0, 0.5) 0px 0px 4px 0px"
	}
};

export default baseTheme;
