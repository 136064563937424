import React, { useContext, useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router"
import Button from "../../components/Button"
import Card, { CardBody } from "../../components/Card"
import Input from "../../components/Input"
import Spinner from "../../components/Spinner"
import { AlertContext } from "../../context/AlertContext"
import { getQueryVariable, useResetPasswordRequest } from "../../util"

import "./ResetPassword.css"

const ResetPassword: React.FC = () => {
	const resetPasswordRequest = useResetPasswordRequest()
	const { addAlert } = useContext(AlertContext)

	const [ newPassword, setNewPassword ] = useState("")

	const resetToken = getQueryVariable("token")
	const navigate = useNavigate()

	useEffect(() => {
		if (!resetToken) {
			addAlert({
				label: "No token was provided, cannot reset password",
				type: "error",
				duration: 4000
			})
			navigate("/login", {replace: true})
		}
	}, [resetToken, navigate, addAlert])

	const resetPassword = () => {
		if (!resetToken) return;
		resetPasswordRequest.sendRequest(resetToken, newPassword)
		.then(() => {
			addAlert({label: "Successfully changed the password", type: "success", duration: 4000})
			navigate("/login", {replace: true})
		}).catch((err) => {
			let message = "Error changing the password"
			if (err.message) message = err.message

			addAlert({label: message, type: "error", duration: 4000})
		})
	}

	return (
		<div className="verify-email-page">
			<div className="verify-email-container">
				<h1>Change Password</h1>
				<Card className="verify-form">
					<CardBody component="form">
						<Input
							id="new-password"
							label="New Password"
							autoComplete="new-password"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
							type="password"
						/>
						<div className="buttons-container">
							<Button
								color="primary"
								loading={resetPasswordRequest.fetching}
								onClick={(e) => {
									e.preventDefault()
									if (resetPasswordRequest.fetching) return
									resetPassword()
								}}
							>
								Change Password
							</Button>
						</div>
					</CardBody>
				</Card>
			</div>
		</div>
	)
}

export default ResetPassword