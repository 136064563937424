import React, { useContext, useEffect, useLayoutEffect, useMemo } from "react"
import { AuthContext } from "../../context/AuthContext"
import { FilterContext } from "../../context/FilterContext"
import { SummaryContext } from "../../context/SummaryContext"
import { TransactionsContext } from "../../context/TransactionsContext"
import { TransactionsResponse } from "../../types/Api"
import { extractTableData, filterAndCombine, filterTokens, formatNumber, getDataTotal, getDataTotals, getDateLabel, getMonthNumber, getYearMonthsAgo } from "../../util"
import CardReport from "../CardReport"

import "./TransactionsReport.css"

const TransactionsReport: React.FC = () => {
	const { loggedIn, user } = useContext(AuthContext)
	const { transactions, transactionTotals, getTotalTransactions, totalsLoading, totalsError } = useContext(TransactionsContext)
	const { totalSelected } = useContext(FilterContext)
	const { summary } = useContext(SummaryContext)
	
	useLayoutEffect(() => {
		if (!loggedIn || !user?.isEmailVerified || user?.requiresPasswordChange) return
		getTotalTransactions()
	}, [loggedIn, user?.isEmailVerified, user?.requiresPasswordChange])
	

	const tableData = useMemo(
		() => extractTableData(filterAndCombine(transactions, totalSelected), ["date", "amount", "transactions"], "date"),
		[transactions, totalSelected]
	)
	const totalData = useMemo<Record<string, any>[]>(() => {
		if (!transactionTotals || !transactionTotals.last30Days) return []
		let newDataItems: Record<string, string | number>[] = []
		const addDataItem = (label: string, item: TransactionsResponse) => {
			if (!item) return;
			let totals = getDataTotals(
				filterAndCombine(item, totalSelected),
				["amount", "transactions"]
			)
			newDataItems.push({
				date: label,
				amount: totals.amount,
				transactions: totals.transactions,
				bold: "true"
			})
		}
		addDataItem("Last 7 Days", transactionTotals.last7Days)
		addDataItem("Last 30 Days", transactionTotals.last30Days)
		addDataItem("This Month", transactionTotals.thisMonth);
		console.log(transactionTotals.previousMonths);
		(transactionTotals?.previousMonths || []).forEach((previousMonth: TransactionsResponse, i: number) => {
			addDataItem(getDateLabel(getMonthNumber(i+1), getYearMonthsAgo(i+1)), previousMonth)
		})
		newDataItems.push({
			date: "All Time",
			amount: getDataTotal(
				filterAndCombine(summary, totalSelected),
				"total_amount"
			),
			transactions: "-", bold: "true"
		})
		return newDataItems
	}, [transactionTotals, totalSelected, summary])

	return (
		<CardReport
			error={totalsError}
			title="Progress Stats"
			data={[...tableData, ...totalData]}
			loading={totalsLoading}
			formatters={{
				amount: (value, data, key) => `$${formatNumber(value || 0)}`,
				transactions: (value) => `${formatNumber(value || 0)}`
			}}

		/>
	)
}

export default TransactionsReport