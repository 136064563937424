import React, { createContext, useContext, useEffect, useState } from "react"
import { SwapsResponse } from "../types/Api"
import { generateColourArr, useSwapsRequest } from "../util"
import { AuthContext } from "./AuthContext"
import { ThemeContext } from "./ThemeContext"

export const SwapContext = createContext<SwapContextData>({
	swaps: [],
	colours: {},
	colourArr: []
})

export interface SwapContextData {
	swaps: SwapsResponse | null | undefined,
	colours: Record<string, any>,
	colourArr: string[]
}

export const SwapContextWrapper: React.FC = ({ children }) => {
	const swapsRequest = useSwapsRequest()
	const { theme } = useContext(ThemeContext)
	const [ colourArr, setColourArr ] = useState<string[]>([])
	const [ colours, setColours ] = useState<Record<string, string>>({})
	const [ sortedSwaps, setSortedSwaps ] = useState<string[]>([])
	const { loggedIn, user } = useContext(AuthContext)

	useEffect(() => {
		if (!loggedIn || !user?.isEmailVerified || user?.requiresPasswordChange) return
		swapsRequest.sendRequest()
	}, [loggedIn, user?.isEmailVerified, user?.requiresPasswordChange])

	useEffect(() => {
		if (swapsRequest.data) setSortedSwaps(swapsRequest.data.sort())
	}, [swapsRequest.fetchedAt])

	useEffect(() => {
		let length = sortedSwaps?.length || 0
		let offset = 100

		let s = theme.type === "light" ? 60 : 60
		let l = theme.type === "light" ? 35 : 60
		setColourArr(generateColourArr(length, offset, s, l))
	}, [sortedSwaps, theme.type])

	useEffect(() => {
		if (!swapsRequest.data) return
		let newColours: Record<string, any> = {};
		swapsRequest.data.forEach((token, i) => newColours[token] = colourArr[i%colourArr.length])
		setColours(newColours)
	}, [colourArr])

	const SwapData: SwapContextData = {
		swaps: swapsRequest.data,
		colours,
		colourArr
	}

	return (
		<SwapContext.Provider value={SwapData}>
			{children}
		</SwapContext.Provider>
	)
}