import React, { useContext, useEffect } from "react"
import { useState } from "react"
import Button from "../../components/Button"
import Card, { CardBody } from "../../components/Card"
import Input from "../../components/Input"
import { AlertContext } from "../../context/AlertContext"
import { AuthContext } from "../../context/AuthContext"
import { isTrue, useLoginRequest } from "../../util"

import { Link, Navigate } from "react-router-dom"

import FormPage from "../../components/FormPage"

const Login: React.FC = () => {
	const { addAlert } = useContext(AlertContext)
	const { login, loggedIn } = useContext(AuthContext)

	const [ email, setEmail ] = useState("")
	const [ password, setPassword ] = useState("")

	const loginRequest = useLoginRequest()

	useEffect(() => {
		if (loginRequest.data)
			login(loginRequest.data.user, loginRequest.data.tokens)
	}, [loginRequest.data])

	if (isTrue(loggedIn)) return <Navigate to="/" replace />

	return (
		<FormPage title="Login to Dashboard">
			<form>
				<Input
					id="email"
					label="Email"
					autoComplete="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<Input
					id="password"
					label="Password"
					type="password"
					autoComplete="current-password"
					enterKeyHint="go"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<Button
					color="primary"
					loading={loginRequest.fetching}
					onClick={(e) => {
						if (loginRequest.fetching) return
						e.preventDefault()
						loginRequest.sendRequest(email, password).then(() => {
							addAlert({type: "success", label: "Successfully logged in", duration: 4000})
						}).catch((err) => {
							addAlert({type: "error", label: err.message.startsWith("Incorrect") ? err.message : "Error logging in", duration: 4000})
						})
					}}
				>
					Login
				</Button>
			</form>
			<Link to="/forgot-password">
				Forgot your password?
			</Link>
		</FormPage>
	)
}

export default Login