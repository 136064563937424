import React, { createContext, useContext, useEffect, useState } from "react"
import { DashboardsResponse } from "../types/Api"
import { alternateArray, generateColourArr, useDashboardsRequest } from "../util"
import { AuthContext } from "./AuthContext"
import { ThemeContext } from "./ThemeContext"

export const DashboardsContext = createContext<DashboardsContextData>({
	dashboards: [],
	colours: {},
	colourArr: []
})

export interface DashboardsContextData {
	dashboards: DashboardsResponse | null | undefined,
	colours: Record<string, any>,
	colourArr: string[]
}

export const DashboardsContextWrapper: React.FC = ({ children }) => {
	const dashboardsRequest = useDashboardsRequest()
	const { theme } = useContext(ThemeContext)
	const [ colourArr, setColourArr ] = useState<string[]>([])
	const [ colours, setColours ] = useState<Record<string, string>>({})
	const [ sortedDashboards, setSortedDashboards ] = useState<string[]>([])
	const { loggedIn, user } = useContext(AuthContext)

	useEffect(() => {
		if (!loggedIn || !user?.isEmailVerified || user?.requiresPasswordChange) return
		dashboardsRequest.sendRequest()
	}, [loggedIn, user?.isEmailVerified, user?.requiresPasswordChange])

	useEffect(() => {
		if (dashboardsRequest.data) setSortedDashboards(dashboardsRequest.data.sort())
	}, [dashboardsRequest.fetchedAt])

	useEffect(() => {
		let length = sortedDashboards?.length || 0
		let offset = 200

		let s = theme.type === "light" ? 60 : 60
		let l = theme.type === "light" ? 35 : 60
		setColourArr(generateColourArr(length, offset, s, l))
	}, [sortedDashboards, theme.type])

	useEffect(() => {
		if (!dashboardsRequest.data) return
		let newColours: Record<string, any> = {};
		dashboardsRequest.data.forEach((token, i) => newColours[token] = colourArr[i%colourArr.length])
		setColours(newColours)
	}, [colourArr])

	const DashboardsData: DashboardsContextData = {
		dashboards: dashboardsRequest.data,
		colours,
		colourArr
	}

	return (
		<DashboardsContext.Provider value={DashboardsData}>
			{children}
		</DashboardsContext.Provider>
	)
}