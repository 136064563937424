import clsx from "clsx"
import React from "react"
import { capitalize, formatNumber } from "../../util"

import "./Table.css"

export type TableValueFormatter = (value: any, data: Record<string, any>, key: string) => string

export interface TableProps {
	data: Record<string, any>[],
	keys?: string[],
	formatters?: Record<string, TableValueFormatter>,
	hideData?: boolean,
	totals?: Record<string, any>[]
}

const Table: React.FC<TableProps> = ({ data, keys, formatters = {}, hideData }) => {
	keys = keys || Object.entries(data[0] || {}).map(([key]) => key).filter((key) => key !== "bold") || []

	const numCols = keys.length
	const numRows = data.length

	const isInLastCol = (itemIndex: number): boolean => {
		return itemIndex % numCols === numCols - 1
	}

	const isInLastRow = (rowIndex: number): boolean => {
		return rowIndex === numRows -1
	}

	return (
		<div className="custom-table" style={{
			"--table-size-columns": numCols,
		}}>
			{keys.map((key, i) => (
				<div
					className={clsx("custom-table-item", "custom-table-head", {"in-last-col": isInLastCol(i), "in-last-row": data.length === 0})}
					key={key}
				>
					<p>{capitalize(key)}</p>
				</div>
			))}
			{data.map((data, rowIndex) => (
				<React.Fragment key={rowIndex}>
					{(keys || []).map((key, i) => (
						<div
							className={
								clsx(
									"custom-table-item",
									{"in-last-col": isInLastCol(i),
									"in-last-row": isInLastRow(rowIndex),
									"font-bold": data.bold}
								)}
							key={key + i}
						>
							<p className={clsx({"private": hideData})}>{hideData ? "" : formatters[key] ? formatters[key](data[key], data, key) : typeof(data[key]) === "number" ? formatNumber(data[key]) : data[key]}</p>
						</div>
					))}
				</React.Fragment>
			))}	

		</div>
	)
}

export default Table