import React, { createContext, useContext, useEffect } from "react"
import { DepositsResponse } from "../types/Api"
import { useDepositsRequest } from "../util"
import { AuthContext } from "./AuthContext"
import { FilterContext } from "./FilterContext"

const defaultValue = {
	deposits: {
		swaps: {},
		dashboards: {}
	},
	loading: true
}

export const DepositsContext = createContext<DepositsContextData>(defaultValue)

export interface DepositsContextData {
	deposits: DepositsResponse,
	loading: boolean
}

export const DepositsContextWrapper: React.FC = ({ children }) => {
	const depositsRequest = useDepositsRequest()
	const { loggedIn, user, tokensFetchedAt } = useContext(AuthContext)
	const { startDate, endDate } = useContext(FilterContext)

	useEffect(() => {
		if (!loggedIn || !user?.isEmailVerified || user?.requiresPasswordChange) return
		depositsRequest.sendRequest({startDate, endDate})
	}, [loggedIn, startDate, endDate, tokensFetchedAt, user?.isEmailVerified, user?.requiresPasswordChange])

	const DepositsData: DepositsContextData = {
		deposits: depositsRequest.data || defaultValue.deposits,
		loading: (depositsRequest.dataHistory.length === 0 || !depositsRequest.data) && (depositsRequest.fetching || !depositsRequest.fetchedAt)
	}

	return (
		<DepositsContext.Provider value={DepositsData}>
			{children}
		</DepositsContext.Provider>
	)
}