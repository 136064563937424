import { alternateArrayNoLoop } from "./data"
import { zeroPad } from "./number"
import { getStringMultiples } from "./string"

export interface RGBColour {
	r: number,
	g: number,
	b: number
}

export const isColourString = (str: string): boolean => {
	let rgbaRegex = /^rgba\s*\(\d+(.\d+)?\s*,\d+(.\d+)?\s*,\d+(.\d+)?\s*,\d+(.\d+)?\s*\)\s*$/
	let rgbRegex = /^rgb\s*\(\d+(.\d+)?\s*,\d+(.\d+)?\s*,\d+(.\d+)?\s*\)\s*$/
	let hexRegex = /^#((\d|[a-fA-F]){3}|(\d|[a-fA-F]){6})$/
	let hslRegex = /^hsl\((\d+(.\d+)?),\s*(\d+(.\d+)?)%,\s*(\d+(.\d+)?)\s*%\)$/
	return rgbaRegex.test(str) || rgbRegex.test(str) || hexRegex.test(str) || hslRegex.test(str)
}

export const hslToRgb= (h: number, s: number, l: number): RGBColour => {
	h = h / 360
	s = s / 100
	l = l / 100

    let r: number = 0;
	let g: number = 0;
	let b: number = 0;

    if (s === 0) r = g = b = l;
    else {
        const hue2rgb = (p: number, q: number, t: number): number => {
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        }

        let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        let p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return {
		r: Math.round(r * 255),
		g: Math.round(g * 255),
		b: Math.round(b * 255)
	};
}

export const getRGBFromColourString = (colour: string): RGBColour => {
	let colourArr: number[] = []
	if (colour.startsWith("rgb")) {
		colour = colour.replace(/^rgba?\(/, "")
		colour = colour.replace(/\s+/, "")
		colour = colour.replace(/\)$/, "")
		colourArr = colour.split(",").splice(0,3).map((strCol) => Number.parseFloat(strCol))
	} else if (colour.startsWith("#")) {
		colour = colour.replace(/^#/, "")
		if (colour.length === 3) {
			colourArr = colour.split("").map((str) => Number.parseInt(str, 16))
		} else if (colour.length === 6) {
			colourArr = getStringMultiples(colour, 2).map((str) => Number.parseInt(str, 16))
		}
	} else if (colour.startsWith("hsl")) {
		let numberMatches = colour.match(/\d+(.\d+)?/g)
		if (!numberMatches) return {r:0,g:0,b:0}
		let [ h, s, l ]: number[] = numberMatches?.map((numStr) => Number.parseFloat(numStr))
		return hslToRgb(h,s,l)
	}

	return {
		r: colourArr[0],
		g: colourArr[1],
		b: colourArr[2],
	}
}

export const getContrastTextColour = (colour: string): string => {
	const rgbCol = getRGBFromColourString(colour);
	let yiqValue = ((rgbCol.r*299)+(rgbCol.g*587)+(rgbCol.b*114))/1000;
	return yiqValue >= 128 ? "#000" : "#fff"
}

export const shadeColor = (color: string, percent: number): string => {
	let parsedCol = getRGBFromColourString(color)

    parsedCol.r = parsedCol.r * (100 + percent) / 100;
    parsedCol.g = parsedCol.g * (100 + percent) / 100;
    parsedCol.b = parsedCol.b * (100 + percent) / 100;

    parsedCol.r = (parsedCol.r<255)?parsedCol.r:255;
    parsedCol.g = (parsedCol.g<255)?parsedCol.g:255;
    parsedCol.b = (parsedCol.b<255)?parsedCol.b:255;

    return RGBObjToHex(parsedCol);
}

export const RGBObjToHex = (rgb: RGBColour): string => {
	return `#${
		zeroPad(Math.floor(rgb.r).toString(16), 2)
	}${
		zeroPad(Math.floor(rgb.g).toString(16), 2)
	}${
		zeroPad(Math.floor(rgb.b).toString(16), 2)
	}`
}

export const getShadedOrThemeColour = (colour: string | undefined, type: "dark" | "main" | "light", colourShadePercentage: number, darkenShadePercentage?: number): string => {
	if (!colour) colour = "primary";
	if (!isColourString(colour)) return `var(--theme-${colour}-${type})`
	if (type === "dark") return shadeColor(colour, -(darkenShadePercentage || colourShadePercentage))
	if (type === "light") return shadeColor(colour, colourShadePercentage)
	return colour;
}

export const generateColourArr = (length: number, offset: number, s: number = 60, l: number = 60): string[] => {
	if (s !== undefined && l === undefined) l = s
	let colourArr = []
	for (let i: number = 0; i < length; i++) {
		colourArr.push(`hsl(${Math.min(360 / length, 180) * i + offset}, ${s}%, ${l}%)`)
	}
	colourArr = alternateArrayNoLoop(colourArr, 4)
	return colourArr
}