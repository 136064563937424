import React, { useContext } from "react"
import { FilterContext } from "../../context/FilterContext"
import { RegistrationsContext } from "../../context/RegistrationsContext"
import { TransactionsContext } from "../../context/TransactionsContext"
import { extractCombinedSortedChartData, formatNumber } from "../../util"
import TopFilteredCard from "../TopFilteredCard"

import "./WorstPerformingTokensFilteredCard.css"

const WorstPerformingTokensFilteredCard: React.FC = () => {
	const { transactions, loading: transactionsLoading } = useContext(TransactionsContext)
	const { registrations, loading: registrationsLoading } = useContext(RegistrationsContext)
	const { totalSelected } = useContext(FilterContext)

	const selectedChartDataSorted = extractCombinedSortedChartData(
		[transactions, registrations], [["amount", "transactions"], ["registrations"]], "token",
		{amount: true, registrations: true, transactions: true}, false, totalSelected
	)

	return (
		<TopFilteredCard
			title="Worst Performing"
			chartDataSorted={selectedChartDataSorted}
			mainKey="token"
			valueKeys={["registrations", "amount", "transactions"]}
			valueTransformers={{amount: (amount: number) => `$${formatNumber(amount)}`}}
			loading={transactionsLoading || registrationsLoading}
			reverse
		/>
	)
}

export default WorstPerformingTokensFilteredCard