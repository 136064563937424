import React, { useContext } from "react"
import { FilterContext } from "../../context/FilterContext"
import IconButton from "../IconButton"

import { ReactComponent as MenuIcon } from "../../svg/icons/menu.svg"

import "./FilterBar.css"
import { NavigationContext } from "../../context/NavigationContext"
import Switch from "../Switch"

export type FilterType = "tokens" | "days" | "display-mode"

export interface FilterBarProps {
	enabled?: FilterType[],
	disabled?: FilterType[]
}

const FilterBar: React.FC<FilterBarProps> = ({ enabled, disabled }) => {
	const { navigationOpen, setNavigationOpen } = useContext(NavigationContext)
	const {
		displayMode, setDisplayMode,
	} = useContext(FilterContext)

	return (
		<div className="filter-bar">
			<div className="left">
				<IconButton
					icon={MenuIcon} 
					onClick={() => setNavigationOpen(!navigationOpen)}
				/>
			</div>
			<div className="filters">
				<div className="form-group">
					<label>Private</label>
					<Switch
						on={displayMode === "private"}
						onChange={(newValue: boolean) => setDisplayMode(newValue ? "private" : "data")}
					/>
				</div>
			</div>
		</div>
	)
}

export default FilterBar