import clsx from "clsx"
import React from "react"

import "./Spinner.css"

export interface SpinnerProps {
	className?: string,
	size?: number,
	color?: "primary" | "contrast"
}

const Spinner: React.FC<SpinnerProps> = ({ size = 48, className, color = "primary" }) => {
	return (
		<span className={clsx("spinner-container", className, color)}>
			<svg className="spinner" viewBox="0 0 66 66" width={`${size/16}rem`} height={`${size/16}rem`}>
				<circle
					cx="33" cy="33" r="30"
					fill="none" strokeWidth="6"
					strokeLinecap="round"
				></circle>
			</svg>
		</span>
	)
}

export default Spinner