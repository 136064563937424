import React, { createContext, useContext, useEffect, useState } from "react"
import { CountriesResponse } from "../types/Api"
import { extractChartData, extractSortedChartData, filterAndCombine, useCountriesRequest } from "../util"
import { AuthContext } from "./AuthContext"
import { FilterContext } from "./FilterContext"

const defaultData = {
	countries: {
		swaps: {},
		dashboards: {}
	}, chartData: [],
	chartDataSorted: {},
	loading: true
}

export const CountriesContext = createContext<CountriesContextData>(defaultData)

export interface CountriesChartDataItem {
	nationality: string,
	amount: number,
	users: number
}

const SortableCountries: Record<string, boolean> = {
	amount: true,
	users: true
}

export type CountriesContextData  = {
	countries: CountriesResponse,
	chartData: Record<string, any>[],
	chartDataSorted: Record<string, Record<string, any>[]>,
	loading: boolean,
}

export const CountriesContextWrapper: React.FC = ({ children }) => {
	const countriesRequest = useCountriesRequest()
	const { loggedIn, user } = useContext(AuthContext)
	const { startDate, endDate, totalSelected } = useContext(FilterContext)
	const [ chartData, setChartData ] = useState<Record<string, any>[]>([])
	const [ chartDataSorted, setChartDataSorted ] = useState<Record<string, Record<string, any>[]>>()

	useEffect(() => {
		if (!loggedIn || !user?.isEmailVerified || user.requiresPasswordChange) return
		countriesRequest.sendRequest({
			startDate,
			endDate
		})
	}, [loggedIn, startDate, endDate, user?.isEmailVerified, user?.requiresPasswordChange])


	useEffect(() => {
		if (!loggedIn || !countriesRequest.data || !countriesRequest.success) return
		let countries = countriesRequest.data || {}
		let chartData: Record<string, any>[] = extractChartData(filterAndCombine(countries, totalSelected), ["users", "amount"], "nationality")
		setChartData(chartData)

		let sorted: Record<string, Record<string, any>[]> = extractSortedChartData(
			filterAndCombine(countries, totalSelected), ["users", "amount"], "nationality",
			SortableCountries, false
		)
		setChartDataSorted(sorted)
	}, [countriesRequest.fetchedAt, totalSelected])

	const CountriesData: CountriesContextData = {
		countries: countriesRequest.data || defaultData.countries,
		chartData,
		chartDataSorted: chartDataSorted || {},
		loading: countriesRequest.fetching || !countriesRequest.fetchedAt
	}

	return (
		<CountriesContext.Provider value={CountriesData}>
			{children}
		</CountriesContext.Provider>
	)
}