import clsx from "clsx";
import React from "react"
import Spinner from "../Spinner";

import "./Button.css"

export type ButtonType = "default" | "props"

export type ButtonProps = {
	className?: string,
	component?: React.ElementType,
	loading?: boolean,
	color?: "primary" | "default",
	styleType?: "contained" | "outlined",
	[key: string]: any;
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button: React.FC<ButtonProps> = ({
	loading = false,
	className,
	component,
	children,
	styleType = "contained",
	color = "default",
	...others
}) => {
	const Component = component || "button"
	return (
		<Component className={clsx("button", className, {loading}, color, styleType)} {...others}>
			{loading && <Spinner color="contrast" size={32} />}
			{children}
		</Component>
	)
}

export default Button