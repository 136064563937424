import React from "react"
import { useRandoms } from "../../util"
import Card, { CardBody, CardTitle } from "../Card"

import "./TopFilteredCardSkeleton.css"

export interface TopFilteredCardSkeletonProps {
	topItemsLength: number
}

const TopFilteredCardSkeleton: React.FC<TopFilteredCardSkeletonProps> = ({ topItemsLength }) => {
	const randoms = useRandoms(8)
	return (
		<Card className="card-filtered filtered-skeleton skeleton">
			<CardTitle title="" titleClass="loader text" />
			<CardBody>
				<div className="filtered-data-container">
					<div className="filtered-list skeleton">
						{new Array(topItemsLength).fill(0).map((_, i) => (
							<div
								className="filtered-list-item"
								key={i + " - " + topItemsLength}
							>
								<p className="label loader text" />
								<div className="filtered-bar-container whitespace-nowrap">
									<div
										className="filtered-bar loader"
										style={{
											width: `${((topItemsLength - i) / topItemsLength) * 60 + 10 + randoms[0] * 10}%`
										}}
									/>
									<span className="filtered-bar-label loader text !w-8" />
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="filtered-hover-data">
					
					<div className="filtered-title-container">
						<div className="filtered-title loader text" />
						<div className="filtered-title-percent loader text" />
					</div>
					<div className="filtered-hover-list">
						{new Array(2).fill(0).map((_, i) => (
							<div className="filtered-hover-list-item" key={i}>
								<div className="loader text"></div>
								<div className="loader text mt-2 !w-20"></div>
							</div>
						))}
					</div>
				</div>
			</CardBody>
		</Card>
	)
}

export default TopFilteredCardSkeleton