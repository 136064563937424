import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { calculateTextBounds } from "../../util"

import "./InputBase.css"

export interface InputClasses {
	root?: string,
	container?: string,
	label?: string
}

export type InputBaseProps = {
	label: string,
	classes?: InputClasses,
	id?: string,
	labelFixed?: boolean,
	selected?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const InputBase= React.forwardRef<HTMLDivElement | null, InputBaseProps>(({
	classes = {},
	id,
	label,
	labelFixed = false,
	selected = false,
	children,
	className,
	...others
}, ref) => {

	const [ labelWidth, setLabelWidth ] = useState("0px")

	useEffect(() => {
		const labelBounds = calculateTextBounds(label, "text-hint")
		setLabelWidth(`${labelBounds.width}px`)
	}, [label])
	
	return (
		<div
			{...others}
			ref={ref}
			className={clsx(
				"input-root",
				classes.root,
				className, {
					"label-fixed": labelFixed,
					"selected": selected
				}
			)}
			style={{
				"--label-width": labelWidth
			}}
		>
			{label && (
				<label
					htmlFor={id}
				>{label}</label>
			)}
			{children}
		</div>
	)
})

export default InputBase