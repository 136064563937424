import updateObj from "object-deep-update";
import type { Theme, ThemeBreakpoint } from "../styles/themes/baseTheme";
import baseTheme from "../styles/themes/baseTheme";
import cloneDeep from "clone-deep"

type DeepPartial<T> = T extends object ? {
    [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export const createTheme = (themeObj: DeepPartial<Theme>): Theme => {
	return updateObj(cloneDeep(baseTheme), cloneDeep(themeObj)) as Theme;
};

export const cssValueToNum = (str: string): number => {
	const matches = /\d+/.exec(str);
	if (!matches || !matches[0]) return 0;
	const num: number = Number.parseFloat(matches[0]);
	if (Number.isNaN(num)) return 0;
	return num;
};

export const getValueStr = (key: string, val: any) => {
	if (key.includes("zindex") && typeof val === "number") return val.toString();
	if (typeof val === "number")
		return `${val}${key.includes("ani") ? "ms" : "px"}`;
	return val;
};

export const addKeyStr = (key: string, str: string) => {
	const suffix = typeof str === "string" && str.endsWith(";") ? "" : ";";
	return `--${key}: ${getValueStr(key, str)}${suffix}`;
};

export const getStyleString = (key: string, val: any) => {
	if (Array.isArray(val)) {
		let newStr = "";
		val.forEach((v, i) => {
			newStr += `--${key}-${i}: ${getValueStr(key, v)};\n`;
		});
		return newStr;
	}
	if (typeof val === "object") {
		let newStr = "";
		Object.entries(val).forEach((entry, i) => {
			const [k, v] = entry;
			newStr += getStyleString(`${key}-${k}`, v);
		});
		return newStr;
	}
	return `${addKeyStr(key, val)}\n`;
};

export const getThemeStyleCSS = (themeObj: Theme) => {
	const str = `:root {\n${getStyleString("theme", themeObj)}}`;
	return str;
};

export const getTextLengthPixels = (string: string, fontSize: string) => {
	const element = document.createElement("p");
	element.innerHTML = string;
	element.style.fontSize = fontSize;
	element.style.display = "inline-block";
	document.body.appendChild(element);
	const { width } = element.getBoundingClientRect();
	document.body.removeChild(element);
	return width;
};

export const getMediaQueryFromBreakpoint = (
	breakpoint: string | number,
	max = false
) => {
	let value: string;
	if (typeof breakpoint === "number") value = `${breakpoint}px`;
	else value = breakpoint;

	const str = `(${max ? "max" : "min"}-width: ${value})`;
	return str;
};

export const getCurrentBreakpoint = (): string | null => {
	let breakpoint: string | null = null;
	if (!baseTheme.breakpoints) return null;
	Object.entries(baseTheme.breakpoints)
		.reverse()
		.forEach(([breakpointStr, themeBreakpoint]: [string, ThemeBreakpoint | undefined]) => {
			if (breakpoint !== null) return;
			let max = false;
			let breakpointValue: string | number = "";
			if (typeof themeBreakpoint === "object") {
				if (themeBreakpoint.max) {
					max = true;
					breakpointValue = themeBreakpoint.max;
				} else if (themeBreakpoint.value) {
					breakpointValue = themeBreakpoint.value;
				}
			} else breakpointValue = themeBreakpoint || "";
			const query = getMediaQueryFromBreakpoint(breakpointValue, max);
			if (window.matchMedia(query).matches) breakpoint = breakpointStr;
		});

	return breakpoint;
};

export const strToNum = (str: string): number => {
	const valueMatches = str.match(/\d+/g);
	if (!valueMatches) return 0;
	if (!valueMatches[0]) return 0;
	const value = Number.parseFloat(valueMatches[0]);
	if (Number.isNaN(value)) return 0;

	const multiplierMap = {
		px: 1,
		rem: 16,
		vh: window.innerWidth / 100,
	};

	let newValue = 0;
	Object.entries(multiplierMap).forEach(([type, multiplier]) => {
		if (str.endsWith(type)) newValue = value * multiplier;
	});
	return newValue;
};

interface MarginSize {
	left: number;
	right: number;
	top: number;
	bottom: number;
	vertical: number;
	horizontal: number;
}

export const getMarginSize = (element: Element | null): MarginSize => {
	if (!element)
		return { left: 0, right: 0, top: 0, bottom: 0, vertical: 0, horizontal: 0 };
	const styles: CSSStyleDeclaration = getComputedStyle(element);
	return {
		left: strToNum(styles.marginLeft),
		right: strToNum(styles.marginRight),
		top: strToNum(styles.marginTop),
		bottom: strToNum(styles.marginBottom),
		vertical: strToNum(styles.marginTop) + strToNum(styles.marginBottom),
		horizontal: strToNum(styles.marginLeft) + strToNum(styles.marginRight),
	};
};
