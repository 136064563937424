import clsx from "clsx"
import React, { useContext } from "react"
import { Bar, BarChart, Cell, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { DashboardsContext } from "../../context/DashboardsContext"
import { FilterContext } from "../../context/FilterContext"
import { SwapContext } from "../../context/SwapContext"
import { ThemeContext } from "../../context/ThemeContext"
import { getNumberFromRange } from "../../types/Api"
import { formatNumber, deepEquals, formatCategory, getKeyObjArr } from "../../util"
import Card, { CardBody, CardTitle } from "../Card"
import CustomTooltip from "../CustomTooltip"
import { BarGraphCardSkeleton } from "../GraphCardSkeleton"

import "./GraphCard.css"

export type GraphCardContainerProps = {
	className?: string;
	title: string;
	figureText?: string;
	figureClass?: string;
	filtered?: boolean;
	filterValues?: string[];
	filterSelected?: string;
	onFilterChange?: (newFilter: string) => void
}

export const GraphCardContainer: React.FC<GraphCardContainerProps> = ({
	title,
	className,
	children,
	figureText,
	filtered, filterSelected, filterValues, onFilterChange,
	figureClass
}) => {
	const { showData } = useContext(FilterContext)

	return (
		<Card className={clsx("graph-card", className)}>
			<CardTitle
				title={title} figure={showData ? figureText : ""}
				filtered={filtered} filterSelected={filterSelected}
				filterValues={filterValues} onFilterChange={onFilterChange}
				figureClass={showData ? "" : "private"}
			/>
			<CardBody>
				{children}
			</CardBody>
		</Card>
	)
}

export interface GraphBaseProps {
	chartComponent: React.ElementType,
	data?: Record<string, any>[],
	xKey?: string,
	noAxis?: boolean,
	className?: string,
	payload?: any[],
	formatter?: (value: number[], name: string, dataItem: Record<string, any>) => string | string[] | undefined
	noTooltip?: boolean,
	[key: string]: any
}

export const GraphBase: React.FC<GraphBaseProps> = ({
	children,
	data,
	chartComponent,
	xKey,
	noAxis = false,
	payload, formatter,
	className,
	noTooltip = false,
	...others
}) => {
	const { theme } = useContext(ThemeContext)
	const { showData } = useContext(FilterContext)
	const Component = chartComponent

	const labelStyles = {fontSize: "0.8rem", fill: theme.text?.muted}
	return (
		<ResponsiveContainer>
			<Component {...others} data={data} className={className}>
				{!noAxis && <>
					<XAxis
						tick={labelStyles}
						height={20}
						padding={{left: 0, right: 0}}
						stroke={labelStyles.fill}
						dataKey={xKey}
					/>
					<YAxis

						tickFormatter={(value: any, index: number) => showData ? formatNumber(value) : "X"}
						tick={labelStyles}
						// width={35}
						padding={{bottom: 0, top: 0}}
						stroke={labelStyles.fill}
					/>
				</>}
				{!noTooltip && <Tooltip
					useTranslate3d
					formatter={formatter || ((value: any) => formatNumber(value))}
					wrapperStyle={{background: theme.background?.paperDark}}
					cursor={{fill: theme.background?.paperLight}}
					content={<CustomTooltip xKey={xKey}  />}
				/>}
				{children}
			</Component>
		</ResponsiveContainer>
	)
}

export interface LineGraphCardProps {
	className?: string,
	data: {[key: string]: any}[],
	title: string,
	keys: string[],
	xKey: string
}

export const LineGraphCard: React.FC<LineGraphCardProps> = ({ data, title, keys, className, xKey }) => {
	const { colours } = useContext(DashboardsContext)

	return (
		<GraphCardContainer title={title} className={className}>
			{data.length > 0 && (
				<GraphBase chartComponent={LineChart} data={data} xKey={xKey}>
					{keys.map((key, i) => (
						<Line
							key={key}
							dataKey={key}
							stroke={colours[key]}
							strokeWidth={2}
							dot={false}
						/>
					))}
				</GraphBase>
			)}
		</GraphCardContainer>
	)
}

export type BarGraphCardProps = {
	className?: string,
	data?: {[key: string]: any}[],
	title?: string,
	keys?: Record<string, string[]>,
	xKey?: string,
	figureText?: string,
	filtered?: boolean,
	filterValues?: string[]
	filterSelected?: string,
	onFilterChange?: (newFilter: string) => void;
	loading?: boolean;
	isAnimationActive?: boolean;
} & Partial<GraphBaseProps>

export const BarGraphCard: React.FC<BarGraphCardProps> = React.memo(({
	title,
	data,
	keys,
	xKey,
	className,
	figureText,
	loading = false,
	filtered, filterValues, filterSelected, onFilterChange,
	...others
}) => {
	const { theme } = useContext(ThemeContext)
	const { colours: dashboardColours } = useContext(DashboardsContext)
	const { colours: swapColours } = useContext(SwapContext)
	const { dateRange, dateUnit } = useContext(FilterContext)

	if (loading) return <BarGraphCardSkeleton bars={getNumberFromRange(dateUnit, dateRange)} />

	const colourMap: Record<string, Record<string, string>> = {
		dashboards: dashboardColours,
		swaps: swapColours
	}

	const keyObjArr = getKeyObjArr(keys || {})

	return (
		<GraphCardContainer
			title={title || ""}
			className={className}
			figureText={figureText}
			filtered={filtered || false}
			filterValues={filterValues}
			filterSelected={filterSelected}
			onFilterChange={onFilterChange}
		>
			{data !== undefined && data.length > 0 && (
				<GraphBase
					chartComponent={BarChart}
					data={data}
					xKey={xKey}
					{...others}
				>
					{keyObjArr.map(({key, category}) => {
						return <Bar
							isAnimationActive={false}
							stackId="stack"
							key={formatCategory(key, category)}
							dataKey={formatCategory(key, category)}
							color={colourMap[category][key]}
							fill={theme.text?.secondary}
						>
							{
								data.map((_, cellIndex: number) => (
									<Cell
										key={cellIndex}
										fill={colourMap[category][key]}
									/>
								))
							}
						</Bar>
					})}
				</GraphBase>
			)}
		</GraphCardContainer>
	)
}, (prevProps: Readonly<BarGraphCardProps>, nextProps: Readonly<BarGraphCardProps>) => deepEquals(prevProps, nextProps))

export interface PieGraphCardProps {
	className?: string,
	data: {[key: string]: any}[],
	title: string,
	keys: string[],
	nameKey: string
}

export const PieGraphCard: React.FC<PieGraphCardProps> = ({
	title,
	data,
	keys,
	nameKey,
	className,
	children
}) => {
	const { theme } = useContext(ThemeContext)
	const { colourArr } = useContext(DashboardsContext)

	return (
		<GraphCardContainer title={title} className={className}>
			{data.length > 0 && (
				<GraphBase
					chartComponent={PieChart}
					noAxis
					className="pie-chart"
				>
					{keys.map((key, i) => (
						<Pie
							data={data}
							key={key}
							dataKey={key}
							strokeWidth={1}
							nameKey={nameKey}
							fill={theme.text?.secondary}
							color={theme.text?.secondary}
							label={(entry) => {
								return entry.amount >= data[4].amount ? entry.name : undefined
							}}
							labelLine={(props) => {
								if (props.amount < data[4].amount) return <rect/>
								const [ p1, p2 ] = props.points
								return (
									<path
										stroke={props.stroke}
										d={`M${p1.x} ${p1.y} l${(p2.x - p1.x)*0.8} ${(p2.y - p1.y)*0.8}`}
										strokeWidth={2}
									/>
								)
							}}
							
						>
							{
								data.map((entry, i) => (
									<Cell
										key={i}
										fill={colourArr[i%colourArr.length]}
									/>
								))
							}
						</Pie>
					))}
				</GraphBase>
			)}
			{children}
		</GraphCardContainer>
	)
}