import React, { useContext } from "react"
import { FilterContext } from "../../context/FilterContext"
import { SummaryContext } from "../../context/SummaryContext"
import { filterAndCombine, getDataTotal } from "../../util"
import PercentageStatCard from "../PercentageStatCard"

import "./UsersStat.css"

const UsersStat: React.FC = () => {
	const { summary, loading } = useContext(SummaryContext)
	const { totalSelected } = useContext(FilterContext)
	
	const filteredSummary = filterAndCombine(summary, totalSelected)
	const totalUsers = getDataTotal(filteredSummary, "total_users")
	const totalCustomers = getDataTotal(filteredSummary, "total_customers")

	return (
		<PercentageStatCard
			title="Total Users"
			total={totalUsers || 0}
			part={totalCustomers}
			totalLabel="Users"
			partLabel="Customers"
			loading={loading}
		/>
	)
}

export default UsersStat