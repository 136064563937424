import React, { useContext } from "react"
import { FilterContext } from "../../context/FilterContext"
import { RegistrationsContext } from "../../context/RegistrationsContext"
import { TransactionsContext } from "../../context/TransactionsContext"
import { extractCombinedSortedChartData, formatNumber } from "../../util"
import TopFilteredCard from "../TopFilteredCard"

import "./BestPerformingTokensFilteredCard.css"

const BestPerformingTokensFilteredCard: React.FC = () => {
	const { transactions, loading: transactionsLoading } = useContext(TransactionsContext)
	const { registrations, loading: registrationsLoading } = useContext(RegistrationsContext)
	const { totalSelected } = useContext(FilterContext)

	const selectedChartDataSorted = extractCombinedSortedChartData(
		[transactions, registrations], [["amount", "transactions"], ["registrations"]],
		"token", {amount: true, registrations: true, transactions: true}, false, totalSelected
	)

	return (
		<TopFilteredCard
			title="Best Performing"
			chartDataSorted={selectedChartDataSorted}
			mainKey="token"
			valueKeys={["registrations", "amount", "transactions"]}
			valueTransformers={{amount: (amount: number) => `$${formatNumber(amount)}`}}
			loading={transactionsLoading || registrationsLoading}
		/>
	)
}

export default BestPerformingTokensFilteredCard