import React from "react"
import CardGroup from "../../components/CardGroup"
import Page from "../../components/Page"
import ThemeSetting from "../../components/ThemeSetting"

import "./SettingsPage.css"

const SettingsPage: React.FC = () => {
	return (
		<Page className="settings-page">
			<CardGroup>
				<ThemeSetting />
			</CardGroup>
		</Page>
	)
}

export default SettingsPage