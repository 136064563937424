import React, { useContext, useEffect, useState } from "react"
import { Navigate } from "react-router"
import { Link } from "react-router-dom"
import Button from "../../components/Button"
import Card, { CardBody } from "../../components/Card"
import FormPage from "../../components/FormPage"
import Input from "../../components/Input"
import { AlertContext } from "../../context/AlertContext"
import { AuthContext } from "../../context/AuthContext"
import { isTrue, useSendForgotPasswordEmailRequest } from "../../util"

const ForgotPassword: React.FC = () => {
	const sendForgottenPasswordEmailRequest = useSendForgotPasswordEmailRequest()
	const [ email, setEmail ] = useState("")
	const [ timeLeft, setTimeLeft ] = useState<number>(0)

	const { loggedIn } = useContext(AuthContext)
	const { addAlert } = useContext(AlertContext)

	const sendEmail = () => {
		sendForgottenPasswordEmailRequest.sendRequest(email)
			.then(() => {
				addAlert({label: "Sent password reset email", type: "success", duration: 4000})
				setTimeLeft(30)
			}).catch(() => {
				addAlert({label: "Error sending email", type: "error", duration: 4000})
			})
	}
	
	useEffect(() => {
		if (timeLeft <= 0) return;
		let timeout = setTimeout(() => {
			setTimeLeft((timeLeft: number) => {
				if (timeLeft - 1 <= 0) {
					clearInterval()
					return 0
				}
				return timeLeft - 1
			})
		}, 1000)
		return () => clearTimeout(timeout)
	}, [timeLeft])

	if (isTrue(loggedIn)) return <Navigate to="/" />

	return (
		<FormPage title="Reset Password">
			<form>
				<Input
					id="email"
					label="Email"
					autoComplete="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<Button
					disabled={timeLeft > 0}
					color="primary"
					loading={sendForgottenPasswordEmailRequest.fetching}
					onClick={(e) => {
						e.preventDefault()
						if (sendForgottenPasswordEmailRequest.fetching) return;
						sendEmail()
					}}
				>
					Send Reset Email {timeLeft > 0 && `(${timeLeft})`}
				</Button>
				<Link to="/login">
					Login to your account
				</Link>
			</form>
		</FormPage>
	)
}

export default ForgotPassword