import React, { createContext, useState } from "react"

export const NavigationContext = createContext<NavigationContextData>({
	navigationOpen: false,
	setNavigationOpen: () => {}
})

export interface NavigationContextData {
	navigationOpen: boolean,
	setNavigationOpen: (newNavigationOpen: boolean) => void
}

export const NavigationContextWrapper: React.FC = ({ children }) => {
	const [ navigationOpen, setNavigationOpen ] = useState(false)

	const NavigationData: NavigationContextData = {
		navigationOpen,
		setNavigationOpen
	}

	return (
		<NavigationContext.Provider value={NavigationData}>
			{children}
		</NavigationContext.Provider>
	)
}