import clsx from "clsx"
import React, { useContext } from "react"
import Alerts from "../../components/Alerts"
import FilterBar from "../../components/FilterBar"
import Navigation from "../../components/Navigation"
import { AuthContext } from "../../context/AuthContext"
import { NavigationContext } from "../../context/NavigationContext"

import CSSBaseline from "../../styles/CSSBaseline"
import { isAuthenticatedUser, isTrue } from "../../util"

import "./Layout.css"

const Layout: React.FC = ({ children }) => {
	const { navigationOpen } = useContext(NavigationContext)
	const { loggedIn, user } = useContext(AuthContext)

	const isAuth = isTrue(loggedIn) && isAuthenticatedUser(user)

	return (
		<>
			<CSSBaseline />
			{isAuth && <Navigation />}
			<Alerts />
			<div className={clsx("children", {"nav-open": navigationOpen && isAuth, "logged-in": isTrue(loggedIn)})}>
				{isAuth && <FilterBar />}
				{children}
			</div>
		</>
	)
}

export default Layout