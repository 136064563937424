import React, { createContext, useContext } from "react"
import { DateRange, DateUnit, DayFilter } from "../types/Api"
import { useLocalState } from "../util"
import { DashboardsContext } from "./DashboardsContext"
import { SwapContext } from "./SwapContext"


export const FilterContext = createContext<FilterContextData>({
	startDate: new Date().toISOString(),
	endDate: undefined,
	dateRange: "1 month",
	setDateRange: () => {},
	dateUnit: "auto",
	setDateUnit: () => {},
	customDateValue: [new Date().toISOString(), new Date().toISOString()],
	setCustomDateValue: () => {},
	selectedDashboards: [],
	setSelectedDashboards: () => {},
	totalDashboards: [],
	selectedSwaps: [],
	totalSwaps: [],
	setSelectedSwaps: () => {},
	displayMode: "data",
	setDisplayMode: () => {},
	showData: true,
	totalSelected: {}
})

export interface FilterContextData {
	startDate: string,
	endDate: string | undefined,
	dateRange: DateRange,
	setDateRange: (newDateRange: DateRange) => void,
	dateUnit: DateUnit,
	setDateUnit: (newDateUnit: DateUnit) => void,
	customDateValue: [string, string],
	setCustomDateValue:  React.Dispatch<React.SetStateAction<[string, string]>>,
	selectedDashboards: string[],
	totalDashboards: string[],
	setSelectedDashboards: (newSelectedDashboards: string[]) => void,
	selectedSwaps: string[],
	totalSwaps: string[],
	setSelectedSwaps: (newSelectedSwaps: string[]) => void,
	displayMode: "data" | "private",
	setDisplayMode: (newDisplayMode: "data" | "private") => void,
	showData: boolean,
	totalSelected: Record<string, string[]>
}

const dayInMs = 1000 * 3600 * 24

const roundDownToNearestDay = (date: Date): Date => new Date(Math.floor(date.getTime() / dayInMs) * dayInMs)
const now = roundDownToNearestDay(new Date(Date.now())).getTime()
const tomorrow = roundDownToNearestDay(new Date(Date.now() + dayInMs)).getTime()



export const FilterContextWrapper: React.FC = ({ children }) => {
	const [ dateRange, setDateRange ] = useLocalState<DateRange>("1 month", "dateRange")
	const [ dateUnit, setDateUnit ] = useLocalState<DateUnit>("auto", "dateUnit")
	const [ customDateValue, setCustomDateValue ] = useLocalState<[string, string]>([new Date(now).toISOString(), new Date(now - 1000 * 3600 * 24).toISOString()], "customRange")
	const [ displayMode, setDisplayMode ] = useLocalState<"data" | "private">("data", "displayMode")
	const [ selectedDashboards, setSelectedDashboards ] = useLocalState<string[]>([], "selectedDashboards")
	const { dashboards } = useContext(DashboardsContext)
	
	const [ selectedSwaps, setSelectedSwaps ] = useLocalState<string[]>([], "selectedSwaps")
	const { swaps } = useContext(SwapContext)

	const dayOffset = 24*3600 * 1000
	const dateOffsetMap: Record<Exclude<DateRange, "custom">, number> = {
		"1 day": dayOffset,
		"1 week": dayOffset * 7,
		"1 month": dayOffset * 30,
		"3 months": dayOffset * 30 * 3,
		"6 months": dayOffset * 30 * 6,
		"1 year": dayOffset * 365,
		"all time": now - new Date("01/01/2022").getTime()
	}

	const FilterData: FilterContextData = {
		startDate: dateRange !== "custom" ? roundDownToNearestDay(new Date(now - dateOffsetMap[dateRange] + dayOffset)).toISOString() : customDateValue[0],
		endDate: dateRange !== "custom" ? new Date(tomorrow).toISOString() : customDateValue[1],
		dateRange,
		setDateRange,
		dateUnit,
		setDateUnit,
		customDateValue,
		setCustomDateValue,
		selectedDashboards,
		setSelectedDashboards,
		totalDashboards: selectedDashboards.length === 0 ? dashboards || [] : selectedDashboards,
		selectedSwaps,
		setSelectedSwaps,
		totalSwaps: selectedSwaps.length === 0 ? swaps || [] : selectedSwaps,
		displayMode,
		setDisplayMode,
		showData: displayMode === "data",
		totalSelected: {
			swaps: selectedSwaps,
			dashboards: selectedDashboards
		}
	}

	return (
		<FilterContext.Provider value={FilterData}>
			{children}
		</FilterContext.Provider>
	)
}