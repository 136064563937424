import React, { useContext } from "react"
import { FilterContext } from "../../context/FilterContext"
import Card, { CardBody, CardTitle } from "../Card/Card"
import TableSkeleton from "../TableSkeleton"

import "./CardReportSkeleton.css"

export interface CardReportSkeletonProps {
	columns?: number,
	rows?: number
}

const CardReportSkeleton: React.FC<CardReportSkeletonProps> = ({ columns = 3, rows }) => {
	if (rows === undefined) rows = 5
	return (
		<Card className="skeleton">
			<CardTitle title="" titleClass="loader text" />
			<CardBody>
				<TableSkeleton
					columns={columns}
					rows={rows}
				/>
			</CardBody>
		</Card>
	)
}

export default CardReportSkeleton