import React, { useContext } from "react"
import { FilterContext } from "../../context/FilterContext"
import { getDataTotalFromExtracted } from "../../util"
import Card, { CardBody, CardTitle } from "../Card/Card"
import CardReportSkeleton from "../CardReportSkeleton"
import Table, { TableValueFormatter } from "../Table"

import "./CardReport.css"

export interface CardReportProps {
	data: Record<string, string | number>[],
	title: string,
	formatters?: Record<string, TableValueFormatter>,
	loading?: boolean,
	totals?: Record<string, string | number>[]
	error?: boolean
}

const CardReport: React.FC<CardReportProps> = ({ title, data, formatters, loading, totals, error }) => {
	const { showData } = useContext(FilterContext)

	const dateMap: Record<string, string> = {
		"30": "Monthly",
		"7": "Weekly",
		"1": "Daily"
	}

	// let newDataItem: Record<string, string | number> = {bold: "true"}
	// Object.entries(data[0] || {}).forEach(([key, value]) => {
	// 	if (key === "date") return newDataItem[key] = dateMap[data.length.toString()]
	// 	newDataItem[key] = getDataTotalFromExtracted(data, key)
	// })
	let newData = [...data]

	if (loading) return <CardReportSkeleton />
	return (		
		<Card>
			<CardTitle title={title} />
			<CardBody>
				{!error ? (
					<Table
						data={newData}
						formatters={formatters}
						hideData={!showData}
						totals={totals}
					/>
				) : (
					"Error getting data"
				)}
			</CardBody>
		</Card>
	)
}

export default CardReport