import React from "react"
import Card, { CardBody, CardTitle } from "../Card"
import FooterStats from "../FooterStats"
import SplitStatVisual, { SplitStatStatistic } from "../SplitStatVisual"

import "./SplitStatCard.css"

export interface SplitStatCardProps {
	loading?: boolean,
	stat: SplitStatStatistic,
	title: string,
	suffix?: string
}

const SplitStatCard: React.FC<SplitStatCardProps> = ({ loading, stat, title, suffix }) => {

	const getLabel = (str: string): string => {
		if (!suffix) return str;
		return `${str} ${suffix}`
	}

	return (
		<Card className="deposits-stat-card">
			<CardTitle title={loading ? "" : title} titleClass={loading ? "loader text" : ""} />
			<CardBody>
				<div className="split-container">
					<SplitStatVisual stat={stat} loading={loading} />
				</div>
			</CardBody>
			{/* <FooterStats
				className="split-card-footer"
				loading={loading}
				data={[
					{label: getLabel("Total"), value: stat.value},
					...(stat.splits?.map((stat) => ({label: getLabel(stat.label), value: stat.value})) || [])
				]}
			/> */}
		</Card>
	)
}

export default SplitStatCard