import React, { useState } from "react"

import "./Input.css"
import InputBase from "../InputBase"

export interface InputClasses {
	root?: string,
	container?: string,
	input?: string,
	label?: string,
}

export type InputProps = {
	label: string,
	classes?: InputClasses,
	id: string,
	labelFixed?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const Input: React.FC<InputProps> = ({ classes = {}, id, label, labelFixed: _labelFixed, ...others }) => {
	const [ value, setValue ] = useState("")
	const [ selected, setSelected ] = useState(false)

	const labelFixed = _labelFixed || selected || value.length > 0 || (others?.value?.toString().length || -1) > 0
	return (
		<InputBase
			label={label}
			labelFixed={labelFixed}
			selected={selected}
			id={id}
		>
			<input
				size={1}
				value={value}
				onChange={(e) => setValue(e.target.value)}
				onFocus={(e) => setSelected(true)}
				onBlur={(e) => setSelected(false)}
				{...others}
				id={id}
				className={classes.input || others.className}
			/>
		</InputBase>
	)
}

export default Input