export const getMonthNumber = (monthsAgo: number = 0) => {
  return ((12 + (new Date().getMonth() - monthsAgo)) % 12) + 1;
};

export const getYearNumber = (yearsAgo: number = 0) => {
  return new Date().getFullYear() - yearsAgo;
};

export const getDateString = (
  monthsAgo: number = 0,
  yearsAgo: number = 0
): string => {
  return new Date(
    `${getMonthNumber(monthsAgo)}/01/${getYearMonthsAgo(yearsAgo)}`
  ).toISOString();
};

export const getYearMonthsAgo = (monthsAgo: number = 0) => {
  let currentMonth = new Date().getMonth();
  let diff = Math.max(monthsAgo - currentMonth, 0);
  let minus = Math.ceil(Math.max(diff / 12, 0));
  return new Date().getFullYear() - Math.abs(minus);
};

const dateArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const getDateLabel = (monthNum: number, year: number): string => {
  return dateArray[monthNum - 1] + " " + year.toString();
};

export const getMonthsSinceJanuary = (): number => {
  const currMonthNumber = getMonthNumber();
  const currYearNumber = getYearNumber();
  //TODO: Every year needs to be updated
  const thenYearNumber = new Date("01/01/2023").getFullYear();
  const thenMonthNumber = new Date("01/01/2023").getMonth();

  const monthsSince =
    (currYearNumber - thenYearNumber) * 12 +
    currMonthNumber -
    thenMonthNumber -
    1;

  console.log("monthsSince ", monthsSince);
  return monthsSince;
};
