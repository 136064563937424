export const arrayEquals = (arr1: any[], arr2: any[], comparatorFunction?: (item1: any, item2: any) => boolean): boolean => {
	if (arr1.length !== arr2.length) return false;
	if (!comparatorFunction) comparatorFunction = (item1, item2) => item1 === item2
	for (let i = 0; i < arr1.length; i++) {
		if (!comparatorFunction(arr1[i], arr2[i])) return false;
	}
	return true;
}

export const deepEquals = (arg1: any, arg2: any): boolean => {
	if (typeof(arg1) !== typeof(arg2)) return false;
	if (typeof(arg1) === "string" || typeof(arg1) === "number" || typeof(arg1) === "boolean") {
		return arg1 === arg2;
	}
	if (typeof(arg1) === "function") {
		return arg1.toString() === arg2.toString();
	}
	if (Array.isArray(arg1)) {
		if (!Array.isArray(arg2)) return false
		if (arg1.length !== arg2.length) return false
		for (let i: number = 0; i < arg1.length; i++) {
			if (!deepEquals(arg1[i], arg2[i])) return false
		}
		return true
	}
	if (typeof(arg1) === "object") {
		const entries = Object.entries(arg1)
		if (entries.length !== Object.entries(arg2).length) return false;
		for (let i: number = 0; i < entries.length; i++) {
			const [ key, value ] = entries[i]
			if (!deepEquals(value, arg2[key])) return false;
		}
	}
	return true;
}