import React, { useContext } from "react"
import { FilterContext } from "../../context/FilterContext"
import { getNumberFromRange } from "../../types/Api"
import { useRandoms } from "../../util"
import Card, { CardBody, CardTitle } from "../Card"

import "./GraphCardSkeleton.css"

export interface BarGraphCardSkeletonProps {
	bars?: number
}

export const BarGraphCardSkeleton: React.FC<BarGraphCardSkeletonProps> = ({ bars = 7 }) => {
	const { dateUnit, dateRange } = useContext(FilterContext)

	const randoms = useRandoms(30)
	
	return (
		<Card className="graph-card bar-graph-skeleton">
			<CardTitle title="" figure="" figureClass="loader text" titleClass="loader text" />
			<CardBody>
				<div className="graph-skeleton-container">
					<div className="x-axis loader" />
					<div className="y-axis loader" />
					<div className="bars">
						{new Array(bars).fill(0).map((_, i) => (
							<div
								key={i + " - " + bars}
								className="bar loader"
								style={{
									height: `${randoms[i % randoms.length] * 10 + 5}rem`,
									width: `calc((100% - 8rem) / ${bars})`
								}}
							/>
						))}
					</div>
				</div>
			</CardBody>
		</Card>
	)
}