import React from "react";

export const getComponentsFromPath = (path: string): string[] => {
	let components = path.split("/");
	if (components[0] === "") components.shift()
	if (components[components.length - 1] === "") components.pop()

	return components;
}

export const getLineBreaks = (str: string): (React.ReactElement | string)[] => {
	let lineBreaks: (React.ReactElement | string)[] = []
	str.split("\n").forEach((line: string, i) => {
		if (i !== 0) lineBreaks.push(React.createElement("br", {key: i}))
		lineBreaks.push(line)
	})
	return lineBreaks;
}

export const capitalize = (str: string): string => {
	return str.split(" ").map((word: string) => word.substring(0, 1).toUpperCase() + word.substring(1)).join(" ")
}

export const getStringMultiples = (str: string, multiples: number): string[] => {
	let charArr = str.split("")
	let endArr: string[] = []
	charArr.forEach((char, i) => {
		let currIndex = Math.floor((i || 1) / multiples)
		if (!endArr[currIndex]) endArr[currIndex] = ""
		endArr[currIndex] = `${endArr[currIndex]}${char}`
	})

	return endArr
}