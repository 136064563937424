import React, { useContext, useEffect, useState } from "react"
import { Navigate } from "react-router"
import { AlertContext } from "../../context/AlertContext"
import { AuthContext } from "../../context/AuthContext"
import { getQueryVariable, isFalse, useSendVerificationEmailRequest, useVerifyEmailRequest } from "../../util"
import Button from "../Button"
import Card, { CardBody } from "../Card"
import FormPage from "../FormPage"
import Input from "../Input"

import "./VerifyEmail.css"

const VerifyEmail: React.FC = () => {
	const verifyEmailRequest = useVerifyEmailRequest()
	const sendVerificationEmailRequest = useSendVerificationEmailRequest()
	const [ verificationToken, setVerificationToken ] = useState(getQueryVariable("token") || "")
	const [ timeLeft, setTimeLeft ] = useState<number>(0)

	const { loggedIn, updateUser, user } = useContext(AuthContext)
	const { addAlert } = useContext(AlertContext)
	
	const verifyUser = () => {
		verifyEmailRequest.sendRequest(verificationToken)
		.then(() => {
			addAlert({label: "Successfully verified user", type: "success", duration: 4000})
			updateUser({isEmailVerified: true})
		}).catch(() => {
			addAlert({label: "Error verifying user", type: "error", duration: 4000})
		})
	}

	const resendEmail = () => {
		sendVerificationEmailRequest.sendRequest()
			.then(() => {
				setTimeLeft(30)
				addAlert({label: "Sent verification email", type: "success", duration: 4000})
			}).catch(() => {
				addAlert({label: "Error sending email", type: "error", duration: 4000})
			})
	}

	useEffect(() => {
		if (verificationToken) {
			verifyUser()
			return
		};
		resendEmail()
	}, [])

	useEffect(() => {
		if (timeLeft <= 0) return;
		let timeout = setTimeout(() => {
			setTimeLeft((timeLeft: number) => {
				if (timeLeft - 1 <= 0) {
					clearInterval()
					return 0
				}
				return timeLeft - 1
			})
		}, 1000)
		return () => clearTimeout(timeout)
	}, [timeLeft])

	if (verifyEmailRequest.success || user?.isEmailVerified) return <Navigate to="/" />

	if (isFalse(loggedIn)) return <Navigate to="/login" />

	return (
		<FormPage title="Verify Email">
			<form>
				<Input
					id="verification-token"
					label="Verification Token"
					autoComplete="off"
					value={verificationToken}
					onChange={(e) => setVerificationToken(e.target.value)}
				/>
				<div className="buttons-container">
					<Button
						color="primary"
						loading={verifyEmailRequest.fetching}
						onClick={(e) => {
							e.preventDefault()
							if (verifyEmailRequest.fetching) return
							verifyUser()
						}}
					>
						Verify Email
					</Button>
					<Button
						disabled={timeLeft > 0}
						color="primary"
						styleType="outlined"
						loading={sendVerificationEmailRequest.fetching}
						onClick={(e) => {
							e.preventDefault()
							if (sendVerificationEmailRequest.fetching) return
							resendEmail()
						}}
					>
						Resend Verification Email {timeLeft > 0 && `(${timeLeft})`}
					</Button>
				</div>
			</form>
		</FormPage>
	)
}

export default VerifyEmail