import clsx from "clsx"
import React, { createContext, useContext } from "react"
import { ComponentItem } from "../../types/Components"

import "./ToggleGroup.css"

export interface ToggleGroupContextData {
	value?: string,
	onChange: (newSelected: string) => void,
	items: (ComponentItem | string)[]
}

const ToggleGroupContext = createContext<ToggleGroupContextData>({value: undefined, onChange: () => {}, items: []})

export type ToggleGroupProps = ToggleGroupContextData & {
	className?: string
}

export const ToggleGroup: React.FC<ToggleGroupProps> = ({ value, onChange, className, items, children }) => {
	const ToggleGroupData = {
		value,
		onChange,
		items
	}

	return (
		<ToggleGroupContext.Provider value={ToggleGroupData}>
			<div className={clsx("toggle-group", className)}>
				{children}
			</div>
		</ToggleGroupContext.Provider>
	)
}

export interface ToggleButtonProps {
	value: string
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ value }) => {
	const { value : selectedValue, onChange, items } = useContext(ToggleGroupContext)
	const item: ComponentItem | string = items.find(
		(currItem: ComponentItem | string) => {
			if (typeof(currItem) === "string")
				return currItem === value
			else return currItem.value === value
		}
	) || ""
	const label = typeof(item) === "string" ? item : item.label

	return (
		<button className={clsx("toggle-button", {selected: selectedValue === value})} onClick={() => onChange(value)}>
			{label}
		</button>
	)
}