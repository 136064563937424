import React, { createContext, useContext, useEffect, useState } from "react"
import { CurrenciesResponse } from "../types/Api";
import { extractChartData, extractSortedChartData, filterAndCombine, useCurrenciesRequest } from "../util";
import { AuthContext } from "./AuthContext";
import { FilterContext } from "./FilterContext";

const defaultValue = {
	currencies: {
		swaps: {},
		dashboards: {}
	}, chartData: [],
	chartDataSorted: {},
	loading: true
}

export const CurrenciesContext = createContext<CurrenciesContextData>(defaultValue)

const SortableCurrencies: Record<string, boolean> = {
	transactions: true,
	amount: true
}

export type CurrenciesContextData  = {
	currencies: CurrenciesResponse,
	chartData: Record<string, any>[],
	chartDataSorted: Record<string, Record<string, any>[]>,
	loading: boolean
}

export const CurrenciesContextWrapper: React.FC = ({ children }) => {
	const currenciesRequest = useCurrenciesRequest()
	const { loggedIn, user } = useContext(AuthContext)
	const { startDate, endDate, totalSelected } = useContext(FilterContext)
	const [ chartData, setChartData ] = useState<Record<string, any>[]>([])
	const [ chartDataSorted, setChartDataSorted ] = useState<Record<string, Record<string, any>[]>>()

	useEffect(() => {
		if (!loggedIn || !user?.isEmailVerified || user?.requiresPasswordChange) return
		currenciesRequest.sendRequest({ startDate, endDate })
	}, [loggedIn, startDate, endDate, user?.isEmailVerified, user?.requiresPasswordChange])


	useEffect(() => {
		if (!loggedIn || !currenciesRequest.data || !currenciesRequest.success) return
		let currencies = currenciesRequest.data || {}

		let chartData: Record<string, any>[] = extractChartData(filterAndCombine(currencies, totalSelected), ["transactions", "amount"], "currency")
		setChartData(chartData)

		let sorted: Record<string, Record<string, any>[]> = extractSortedChartData(
			filterAndCombine(currencies, totalSelected), ["transactions", "amount"], "currency",
			SortableCurrencies, false
		)
		setChartDataSorted(sorted)
	}, [currenciesRequest.fetchedAt, totalSelected])

	const CurrenciesData: CurrenciesContextData = {
		currencies: currenciesRequest.data || defaultValue.currencies,
		chartData,
		chartDataSorted: chartDataSorted || {},
		loading: currenciesRequest.fetching || !currenciesRequest.fetchedAt
	}

	return (
		<CurrenciesContext.Provider value={CurrenciesData}>
			{children}
		</CurrenciesContext.Provider>
	)
}