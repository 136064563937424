import clsx from "clsx";
import React from "react"

import "./IconButton.css"

export interface IconButtonProps {
	className?: string;
	icon: React.ElementType;
	component?: React.ElementType;
	size?: number;
	disabled?: boolean;
	[key: string]: any;
}

const IconButton: React.FC<IconButtonProps> = ({
	className,
	icon,
	component,
	size = 24,
	disabled = false,
	...others
}) => {
	const Component = component || "button"
	const Icon = icon
	return (
		<Component
			className={clsx("icon-button", className)}
			style={{padding: `${size / 48}rem`}}
			disabled={disabled}
			{...others}
		>
			<Icon style={{width: `${size / 16}rem`, height: `${size / 16}rem`}} />
		</Component>
	)
}

export default IconButton