import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, AxiosResponseHeaders, Method } from "axios"
import { minMax } from "./number";
import {
	DayFilter, APIError,
	CountriesResponse, CurrenciesResponse,
	DashboardsResponse, DepositsResponse,
	LoginResponse, RegistrationsResponse,
	SummaryResponse, TokensData,
	TransactionsResponse, UserData, SwapsResponse, DateUnit, DateArgs
} from "../types/Api";
import { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { AuthContext } from "../context/AuthContext";

export type URLString = `http://${string}.${string}` | `https://${string}.${string}` | `/${string}`
export type CreateRequestOptions = AxiosRequestConfig & {
	method?: Method
}

export type CreateRequestResponse<T, K> = {
	progress: number;
	uploadProgress: number;
	downloadProgress: number;
	data: T | null | undefined;
	dataHistory: DataHistoryItem<T | null | undefined>[];
	requestStatus: RequestStatus;
	responseStatusCode: number | undefined;
	sendRequest: K;
	fetching: boolean;
	finished: boolean;
	success: boolean;
	error: string | undefined;
	fetchedAt: number;
  }

export type RequestStatus = "NOT_STARTED" | "UPLOADING" | "DOWNLOADING" | "FINISHED";
export type DataHistoryItem<T> = {
	utcReceived: number,
	data: T
}

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const useRequest = <T = Record<string, unknown>>(url: URLString, options?: CreateRequestOptions): CreateRequestResponse<T, (options: AxiosRequestConfig) => Promise<AxiosResponse<T> | AxiosError>> => {
	const unmountedRef = useRef(false)
	const [uploadProgress, setUploadProgress] = useState(0)
	const [downloadProgress, setDownloadProgress] = useState(0)
	const [progress, setProgress] = useState(0)
	const [fetchedAt, setFetchedAt] = useState(0)

	const [requestStatus, setRequestStatus] = useState<RequestStatus>("NOT_STARTED")
	const [responseStatusCode, setResponseStatusCode] = useState<number>()

	const [fetching, setFetching] = useState(false)
	const [finished, setFinished] = useState(true)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState<string>();

	const [data, setData] = useState<T | null>();
	const [dataHistory, setDataHistory] = useState<DataHistoryItem<T>[]>([]);

	const addDataToHistory = useCallback((dataToAdd: T | null | undefined) => {
		let newUtc = Date.now()
		if (!dataToAdd) return;
		let newHistory: DataHistoryItem<T>[] = [...(dataHistory || []), {
			utcReceived: newUtc,
			data: dataToAdd
		}].sort((a, b) => a.utcReceived - b.utcReceived)
		setDataHistory(newHistory)
	}, [dataHistory])

	useEffect(() => {
		addDataToHistory(data)
	}, [data])

	useEffect(() => (() => {unmountedRef.current = true}), [])

	useEffect(() => {
		setProgress(
			Math.min(uploadProgress * 0.5 + downloadProgress * 0.5, 1)
		)
	}, [uploadProgress, downloadProgress])

	const headers: AxiosRequestHeaders = {
		"Content-Type": "application/json",
		...(options?.headers || {})
	}

	const createProgressFunction = (signalUpdateFunc: (num: number) => void) => {
		return (progress: ProgressEvent) => {
			if (!progress.loaded && requestStatus !== "UPLOADING") setRequestStatus("UPLOADING")

			const target = progress.target as XMLHttpRequest;
			let totalLength: number = 1;
			if (progress.lengthComputable) totalLength = progress.total;
			else totalLength = Number.parseInt(target.getResponseHeader("content-length") || "0") || 1;

			const newProgress: number = minMax(progress.loaded / totalLength, 0, 1)
			signalUpdateFunc(newProgress)
		}
	}

	const axiosInstance = axios.create({
		baseURL: url.startsWith("http") ? "" : baseUrl,
		onUploadProgress: createProgressFunction(setUploadProgress),
		onDownloadProgress: createProgressFunction(setDownloadProgress),
		method: "GET",
		headers,
		...options,
		transformResponse: (data: any, headers?: AxiosResponseHeaders) => {
			if (typeof(data) == "string" && data !== "") data = JSON.parse(data)
			if (options && options.transformResponse) {
				if (Array.isArray(options.transformResponse)) {
					data = options.transformResponse.reduce((prevValue, currentTransformer, i) => {
						data = currentTransformer(data, headers)
					}, data)
				} else {
				 	data = options.transformResponse(data, headers)
				}
			}
			return data;
		}
		
	})

	let lastReceived = 0;
	const sendRequest = async (newOptions?: AxiosRequestConfig): Promise<AxiosResponse<T> | AxiosError> => {
		return new Promise((resolve, reject) => {
			if (unmountedRef.current) return;
			ReactDOM.unstable_batchedUpdates(() => {
				setUploadProgress(0)
				setDownloadProgress(0)
				setFetching(true)
				setSuccess(false)
				setFinished(false)
				setError(undefined)
				setData(null)
				setRequestStatus("UPLOADING")
				setResponseStatusCode(undefined)
			})
			axiosInstance({...(newOptions || {}), url: url + (newOptions?.url || "")})
				.then((res: AxiosResponse<T>) => {
					if (unmountedRef.current) return;
					let now = Date.now();
					if (lastReceived > now) return reject("Completed too late");
					lastReceived = now;
					ReactDOM.unstable_batchedUpdates(() => {
						setFetching(false)
						setSuccess(true)
						setProgress(1)
						setResponseStatusCode(res.status)
						setFinished(true)
						setData(res.data || null)
						setFetchedAt(now)
					})
					resolve(res)
			}).catch((error: AxiosError) => {
				if (unmountedRef.current) return;
				let responseError: APIError = error?.response?.data || {
					code: 500,
					message: "Internal server error",
				}
				console.error(responseError.message)
				

				ReactDOM.unstable_batchedUpdates(() => {
					setFetching(false)
					setProgress(1)
					setUploadProgress(1)
					setDownloadProgress(1)
					setSuccess(false)
					setFinished(true);
					setError(error.response?.data.message || error.message)
					setResponseStatusCode(error.response?.status)
					setFetchedAt(Date.now())
				})
				reject(responseError)
			})
		})
	}

	let returnVal: CreateRequestResponse<T, (options: AxiosRequestConfig) => Promise<AxiosResponse<T> | AxiosError>> = {
		progress, uploadProgress, downloadProgress,
		data, dataHistory,
		requestStatus, responseStatusCode,
		sendRequest,
		finished, fetching, success, error,
		fetchedAt
	}

	return returnVal;
}

export const useAuthRequest = <T = Record<string, unknown>>(url: URLString, options: AxiosRequestConfig = {}, suppliedTokenRef?: MutableRefObject<TokensData>): CreateRequestResponse<T, (newOptions?: AxiosRequestConfig) => Promise<AxiosResponse | AxiosError>> => {
	const request: CreateRequestResponse<T, (options: AxiosRequestConfig) => Promise<AxiosResponse<T> | AxiosError>> = useRequest<T>(url, options)
	const { tokensRef, tokens, refreshTokens } = useContext(AuthContext)
	
	const newSendRequest = async (newOptions: AxiosRequestConfig = {}): Promise<AxiosResponse<T> | AxiosError> => {
		let totalOptions = {
			...options,
			...newOptions
		}

		let token = (suppliedTokenRef || tokensRef).current.access?.token;
		let expires = (suppliedTokenRef || tokensRef).current.access?.expires

		if (!expires || Date.now() > new Date(expires || 0).getTime()) {
			let success = true
			let error = false
			let tokenRes = await refreshTokens().catch((err) => {
				error = err
				success = false
			});
			if (!success) {
				return Promise.reject(error)
			}
			tokenRes = tokenRes as AxiosResponse<TokensData>
			token = tokenRes.data.access.token
		}

		if (!totalOptions.headers) totalOptions.headers = {}
		if (!token) return Promise.reject({
			code: 401
		});
		totalOptions.headers["Authorization"] = "BEARER " + token

		return new Promise((resolve, reject) => {
			request.sendRequest(totalOptions).then((res) => resolve(res))
				.catch((err: AxiosError) => {
					if (err.code?.toString() === "401") {
						refreshTokens()
					}
					reject(err)
				})
		})
	}

	return {
		...request,
		sendRequest: newSendRequest
	}
}

export const useLoginRequest = (): CreateRequestResponse<LoginResponse, (email: string, password: string) => Promise<AxiosResponse<LoginResponse> | AxiosError>> => {
	const request = useRequest<LoginResponse>("/auth/login")

	const newSendRequest = (email: string, password: string) => {
		return request.sendRequest({
			data: {email, password},
			method: "POST",
		})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useRefreshTokensRequest = (): CreateRequestResponse<TokensData, (refreshToken: string) => Promise<AxiosResponse<TokensData> | AxiosError>> => {
	const request = useRequest<TokensData>("/auth/refresh-tokens")

	const newSendRequest = (refreshToken: string) => {
		return request.sendRequest({
			data: {refreshToken},
			method: "POST",
		})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useDashboardsRequest = (): CreateRequestResponse<DashboardsResponse, () => Promise<AxiosResponse<DashboardsResponse> | AxiosError>> => {
	const request = useAuthRequest<DashboardsResponse>("/dashboards")

	const newSendRequest = () => {
		return request.sendRequest()
	}

	return {...request, sendRequest: newSendRequest}
}

export const useSwapsRequest = (): CreateRequestResponse<SwapsResponse, () => Promise<AxiosResponse<SwapsResponse> | AxiosError>> => {
	const request = useAuthRequest<SwapsResponse>("/swaps")

	const newSendRequest = () => {
		return request.sendRequest()
	}

	return {...request, sendRequest: newSendRequest}
}

export const useRegistrationsRequest = (): CreateRequestResponse<RegistrationsResponse, (args: DateArgs) => Promise<AxiosResponse<RegistrationsResponse> | AxiosError>> => {
	const request = useAuthRequest<RegistrationsResponse>("/registrations")

	const newSendRequest = ({
		startDate,
		endDate,
		unit
	}: DateArgs) => {
		return request.sendRequest({
			params: {
				startDate,
				endDate,
				...(unit !== "auto" ? {unit} : {})
			}
		})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useTransactionsRequest = (): CreateRequestResponse<TransactionsResponse, (args: DateArgs) => Promise<AxiosResponse<TransactionsResponse> | AxiosError>> => {
	const request = useAuthRequest<TransactionsResponse>("/transactions")

	const newSendRequest = ({
		startDate,
		endDate,
		unit
	}: DateArgs) => {
		return request.sendRequest({
			params: {
				...(unit !== "auto" ? {unit} : {}),
				startDate,
				endDate
			}
		})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useSummaryRequest = (): CreateRequestResponse<SummaryResponse, () => Promise<AxiosResponse<SummaryResponse> | AxiosError>> => {
	const request = useAuthRequest<SummaryResponse>("/summary")

	const newSendRequest = () => {
		return request.sendRequest()
	}

	return {...request, sendRequest: newSendRequest}
}

export const useCountriesRequest = (): CreateRequestResponse<CountriesResponse, (args: DateArgs<false>) => Promise<AxiosResponse<CountriesResponse> | AxiosError>> => {
	const request = useAuthRequest<CountriesResponse>("/countries")

	const newSendRequest = (args: DateArgs<false>) => {
		return request.sendRequest({params: {
			startDate: args.startDate,
			endDate: args.endDate
		}})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useCurrenciesRequest = (): CreateRequestResponse<CurrenciesResponse, (args: DateArgs<false>) => Promise<AxiosResponse<CurrenciesResponse> | AxiosError>> => {
	const request = useAuthRequest<CurrenciesResponse>("/currencies")

	const newSendRequest = (args: DateArgs<false>) => {
		return request.sendRequest({params: {
			startDate: args.startDate,
			endDate: args.endDate
		}})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useDepositsRequest = (): CreateRequestResponse<DepositsResponse, (args: DateArgs<false>) => Promise<AxiosResponse<DepositsResponse> | AxiosError>> => {
	const request = useAuthRequest<DepositsResponse>("/deposits")

	const newSendRequest = (args: DateArgs<false>) => {
		return request.sendRequest({
			params: {
				startDate: args.startDate,
				endDate: args.endDate
			}
		})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useGetUserRequest = (suppliedTokenRef?: MutableRefObject<TokensData>): CreateRequestResponse<UserData, (id: string) => Promise<AxiosResponse<UserData> | AxiosError>> => {
	const request = useAuthRequest<UserData>("/users", {}, suppliedTokenRef)

	const newSendRequest = (id: string) => {
		return request.sendRequest({
			url: "/" + id
		})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useVerifyEmailRequest = (): CreateRequestResponse<null, (verifyToken: string) => Promise<AxiosResponse<null> | AxiosError>> => {
	const request = useAuthRequest<null>("/auth/verify-email")

	const newSendRequest = (verifyToken: string) => {
		return request.sendRequest({
			params: {token: verifyToken},
			method: "POST"
		})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useSendVerificationEmailRequest = (): CreateRequestResponse<null, () => Promise<AxiosResponse<null> | AxiosError>> => {
	const request = useAuthRequest<null>("/auth/send-verification-email")

	const newSendRequest = () => {
		return request.sendRequest({
			method: "POST"
		})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useChangePasswordRequest = (): CreateRequestResponse<null, (currentPassword: string, newPassword: string) => Promise<AxiosResponse<null> | AxiosError>> => {
	const request = useAuthRequest<null>("/auth/change-password")
	
	const newSendRequest = (currentPassword: string, newPassword: string) => {
		return request.sendRequest({
			method: "POST",
			data: {
				oldPassword: currentPassword,
				newPassword
			}
		})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useResetPasswordRequest = (): CreateRequestResponse<null, (resetToken: string, newPassword: string) => Promise<AxiosResponse<null> | AxiosError>> => {
	const request = useRequest<null>("/auth/reset-password")

	const newSendRequest = (resetToken: string, newPassword: string) => {
		return request.sendRequest({
			params: {token: resetToken},
			data: {
				password: newPassword
			},
			method: "POST"
		})
	}

	return {...request, sendRequest: newSendRequest}
}

export const useSendForgotPasswordEmailRequest = (): CreateRequestResponse<null, (email: string) => Promise<AxiosResponse<null> | AxiosError>> => {
	const request = useAuthRequest<null>("/auth/forgot-password")

	const newSendRequest = (email: string) => {
		return request.sendRequest({
			method: "POST",
			data: {email}
		})
	}

	return {...request, sendRequest: newSendRequest}
}