import clsx from "clsx";
import React, { useContext, useMemo } from "react"
import { TooltipProps } from "recharts"
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { DashboardsContext } from "../../context/DashboardsContext";
import { FilterContext } from "../../context/FilterContext";
import { SwapContext } from "../../context/SwapContext";
import { alternateArray, alternateArrayNoLoop, capitalize } from "../../util";

import "./CustomTooltip.css"

export type CustomTooltipProps = {
	xKey?: string
} & TooltipProps<number[], string>

const CustomTooltip: React.FC<CustomTooltipProps> = ({
	active,
	payload,
	xKey,
	label,
	formatter,
	...others
}) => {
	const { colours: dashboardColours } = useContext(DashboardsContext)
	const { colours: swapColours } = useContext(SwapContext)
	const { showData } = useContext(FilterContext)

	const getValue = (a: Payload<number[], string>): number  => {
		if (typeof (a.value) === "number") return a.value
		if (Array.isArray(a.value)) return a.value[0] || 0
		return 0;
	}

	let sortedArr = useMemo<Payload<number[], string>[]>(() => {
		let filtered =  (payload || []).filter((payloadItem) => ((payloadItem?.value?.[0]) || [0]) > 0) || []
		filtered = filtered.sort((a, b) => getValue(b) - getValue(a))
		return alternateArrayNoLoop(filtered, 2)
	}, [payload])

	if (!active) return null;

	let total: number[] = (payload || []).reduce((accu: number[], currPayload: Payload<number[], string>, i: number) => {
		if (Array.isArray((currPayload.value || []))) {
			(currPayload.value || []).forEach((value, i) => accu[i] = (accu[i] || 0) + value)
		} else if (typeof(currPayload.value) === "number") accu[0] += currPayload.value
		return accu
	}, [0])

	const getColour = (name: string): string => {
		if (name.endsWith(" swap")) {
			return swapColours[name.replace(/ swap$/, "")]
		}
		return dashboardColours[name]
	}

	return (
		<div className="tooltip">
			<div className="tooltip-details">
				{payload && (
					<>
						<p className="tooltip-label">
							{`${xKey ? capitalize(xKey) + ": " : ""}${label || payload[0].name}`}
						</p>
						<p className="tooltip-label">
							{`Total: ${showData ? formatter?.(total, "Total", {payload: {value: total, date: label}}) : ""}`}
						</p>
						<div className={clsx("tooltip-list", {grid: payload.length >= 4})}>
							{sortedArr.map((payloadItem: Payload<number[], string>) => (
								<div
									key={payloadItem.name}
									className="tooltip-item"
									style={{color: getColour(payloadItem.name || "")}}
								>
									<span className="tooltip-item-name">{payloadItem.name}</span>
									<span className="tooltip-item-separator">:</span>
									<div className="flex-1" />
									<span className={clsx("tooltip-item-value", {"private": !showData, "!w-[2em]": !showData})}>
										{showData ? formatter?.(payloadItem.value, payloadItem.name, payloadItem) || payloadItem.value : ""}
									</span>
								</div>
							))}
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default CustomTooltip