import { AxiosResponse } from "axios"
import React, { createContext, useContext, useEffect, useState } from "react"
import { dateRanges, TransactionsResponse } from "../types/Api"
import { filterTokens, getDataPeriod, getDataPeriodTotals, getDateLabel, getDateString, getMonthNumber, getMonthsSinceJanuary, getYearMonthsAgo, useTransactionsRequest } from "../util"
import { AuthContext } from "./AuthContext"
import { FilterContext } from "./FilterContext"

const defaultTotals = {
	last7Days: {swaps: {}, dashboards: {}},
	last30Days: {swaps: {}, dashboards: {}},
	thisMonth: {swaps: {}, dashboards: {}},
	previousMonths: [],
}

export const TransactionsContext = createContext<TransactionsContextData>({
	transactions: {swaps: {}, dashboards: {}},
	loading: true,
	transactionTotals: defaultTotals,
	getTotalTransactions: () => {},
	totalsLoading: false,
	totalsError: false
})

export interface TransactionTotals {
	last7Days: TransactionsResponse,
	last30Days: TransactionsResponse,
	thisMonth: TransactionsResponse,
	previousMonths: TransactionsResponse[],
}

export interface TransactionsContextData {
	transactions: TransactionsResponse,
	loading: boolean,
	transactionTotals: TransactionTotals,
	getTotalTransactions: Function,
	totalsLoading: boolean
	totalsError: boolean,
}

export const TransactionsContextWrapper: React.FC = ({ children }) => {
	const transactionsRequest = useTransactionsRequest()
	const [ transactions, setTransactions ] = useState<TransactionsResponse | undefined>()
	const [ transactionTotals, setTransactionTotals ] = useState<TransactionTotals>()
	const [ fetching, setFetching ] = useState(false)
	const [ totalsFetching, setTotalsFetching ] = useState(false)
	const [ totalsError, setTotalsError ] = useState(false)

	const { loggedIn, user } = useContext(AuthContext)
	const { dateUnit, startDate, endDate } = useContext(FilterContext)

	useEffect(() => {
		if (!loggedIn || !user?.isEmailVerified || user?.requiresPasswordChange) return
		console.log(dateUnit, startDate, endDate)
		setFetching(true)
		transactionsRequest.sendRequest({
			startDate,
			endDate,
			unit: dateUnit
		}).then((res) => {
			let castedRes = res as AxiosResponse<TransactionsResponse>
			if (castedRes.data) setTransactions(castedRes.data)
		}).finally(() => setFetching(false))
	}, [loggedIn, dateUnit, endDate, startDate, user?.isEmailVerified, user?.requiresPasswordChange])

	const getTotalTransactions = () => {
		setTotalsFetching(true)
		let promises: Promise<any>[] = []
		promises.push(transactionsRequest.sendRequest({
			startDate: new Date(Date.now() - 30*24*3600*1000).toISOString(),
			unit: dateUnit
		}).then((res) => {
			let castedRes = res as AxiosResponse<TransactionsResponse>
			setTransactionTotals((transactionTotals: TransactionTotals | undefined) => ({
				...transactionTotals,
				last30Days: {
					dashboards: castedRes.data.dashboards,
					swaps: castedRes.data.swaps,
				},
				last7Days: {
					dashboards: getDataPeriod(castedRes.data.dashboards, [0, 7], "date"),
					swaps: getDataPeriod(castedRes.data.swaps, [0, 7], "date"),
				},
			}) as TransactionTotals)
		}))
		const dateString = getDateString()
		promises.push(transactionsRequest.sendRequest({
			startDate: dateString,
			unit: dateUnit
		}).then((res) => {
			let castedRes = res as AxiosResponse<TransactionsResponse>
			setTransactionTotals((transactionTotals) => ({
				...transactionTotals,
				thisMonth: {
					swaps: castedRes.data.swaps,
					dashboards: castedRes.data.dashboards,
				},
			}) as TransactionTotals)
		}))
		promises = promises
			.concat(new Array(getMonthsSinceJanuary())
			.fill(0).map((_, i) => {
				return transactionsRequest.sendRequest({
					startDate: getDateString(i+1, i+1),
					endDate: getDateString(i, i),
					unit: dateUnit
				}).then((res) => {
					let castedRes = res as AxiosResponse<TransactionsResponse>
					setTransactionTotals((transactionTotals) => {
						let newPreviousMonths = [...(transactionTotals?.previousMonths || [])]
						newPreviousMonths[i] = castedRes.data as TransactionsResponse
						return {
							...transactionTotals,
							previousMonths: newPreviousMonths
						} as TransactionTotals
					})
				})
			}))
		Promise.all(promises).then(() => {
			setTotalsFetching(false)
		}).catch((err) => {
			console.log("ERROR")
			setTotalsFetching(false)
			setTotalsError(true)
		})
	}

	const TransactionsData: TransactionsContextData = {
		transactions: transactions || defaultTotals.last30Days,
		transactionTotals: transactionTotals || defaultTotals,
		loading: fetching || !transactionsRequest.fetchedAt,
		getTotalTransactions,
		totalsLoading: totalsFetching,
		totalsError
	}

	return (
		<TransactionsContext.Provider value={TransactionsData}>
			{children}
		</TransactionsContext.Provider>
	)
}