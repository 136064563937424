import clsx from "clsx"
import React, { useContext } from "react"
import { Navigate } from "react-router"
import { AuthContext } from "../../context/AuthContext"
import { isFalse } from "../../util"

import "./Page.css"

export interface PageProps {
	className?: string
}

const Page: React.FC<PageProps> = ({ className, children }) => {
	const { loggedIn, user } = useContext(AuthContext)
	if (isFalse(loggedIn)) return <Navigate replace to="/login" />
	if (user && !user.isEmailVerified) return <Navigate replace to="/verify-email" />
	if (user && user.requiresPasswordChange) return <Navigate replace to="/change-password" />

	return (
		<main className={clsx(className)}>
			{children}
		</main>
	)
}

export default Page