import clsx from "clsx"
import React, { Children, useContext } from "react"
import { FilterContext } from "../../context/FilterContext"
import { ThemeContext } from "../../context/ThemeContext"
import { formatNumber } from "../../util"
import Card, { CardBody, CardTitle } from "../Card"
import CircularProgressIndicator from "../CircularProgressIndicator"
import FooterStats from "../FooterStats"
import PercentageStatCardSkeleton from "../PercentageStatCardSkeleton"

import "./PercentageStatCard.css"

export interface PercentageStatCardProps {
	title: string
	total: number
	part: number
	loading?: boolean
	partLabel?: string
	totalLabel?: string
}

const PercentageStatCard: React.FC<PercentageStatCardProps> = ({
	title,
	total,
	loading,
	part,
	partLabel,
	totalLabel,
	children
} )=> {
	const { theme } = useContext(ThemeContext)
	const { showData } = useContext(FilterContext)

	if (loading) return <PercentageStatCardSkeleton />

	return (
		<Card className="percentage-stat-card">
			<CardTitle title={title} />
			<CardBody>
				<div className="circle-container">
					<CircularProgressIndicator
						size={40}
						fraction={(part / total) || 0}
						colour={theme.success?.main}
					/>
					<div className="progress-label-container">
						<p className={clsx("progress-total", {private: !showData, "!w-[3em]": !showData})}>{showData ? formatNumber(total) : ""}</p>
						<p className="progress-total-label">{totalLabel}</p>
					</div>
				</div>
				<div className="percentage-stats-container">
					<div className="percentage-stats-item part">
						<p className="title">{partLabel}</p>
						<p className={clsx("figure", {private: !showData})}>{showData ? `${Math.floor(part * 100 / total) || 0}%` : ""}</p>
					</div>
					<div className="percentage-stats-item not-part">
						<p className="title">Not {partLabel}</p>
						<p className={clsx("figure", {private: !showData})}>{showData ? `${100 -Math.floor(part * 100 / total) || 0}%` : ""}</p>
					</div>
				</div>
			</CardBody>
			{/* <FooterStats
				data={[
					{
						label: totalLabel || "",
						value: total
					},
					{
						label: partLabel || "",
						value: part
					},
					{
						label: `Not ${partLabel}` || "",
						value: total - part
					}
				]}
			/> */}
		</Card>
	)
}

export default PercentageStatCard