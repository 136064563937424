import React from "react"
import CardGroup from "../../components/CardGroup"
import FilterBar from "../../components/FilterBar"

import Page from "../../components/Page"
import TransactionsReport from "../../components/TransactionsReport"

import "./Reports.css"

const Reports: React.FC = () => {
	return (
		<Page className="reports-page">
			<CardGroup>
				<TransactionsReport />
			</CardGroup>
		</Page>
	)
}

export default Reports