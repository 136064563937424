import clsx from "clsx"
import React, { LegacyRef, useCallback, useContext, useEffect, useRef, useState } from "react"
import { FilterContext } from "../../context/FilterContext"
import { ThemeContext } from "../../context/ThemeContext"
import { Size } from "../../types/Components"
import { formatNumber, generateColourArr, getShadedOrThemeColour, isColourString, shadeColor, useEventListener } from "../../util"

import "./SplitStatVisual.css"

const darkenColourShadePercentage = (themeType: "light" | "dark") => themeType === "light" ? 30 : 30
const lightenColourShadePercentage = (themeType: "light" | "dark") => themeType === "light" ? 80 : 80

export interface SplitStatStatistic {
	value: number,
	label: string,
	icon?: React.ElementType,
	splits?: SplitStatStatistic[],
	colour?: string,
}

export type SplitStatLabelProps = {
	stat: SplitStatStatistic,
	loading?: boolean,
	left?: boolean,
	colourIndex?: number
} & React.HTMLAttributes<HTMLDivElement>

const defaultColours = generateColourArr(16, 20)

export const SplitStatLabel = React.forwardRef<HTMLDivElement, SplitStatLabelProps>(({
	stat, loading, left, colourIndex, ...others
}, ref) => {
	const { showData } = useContext(FilterContext)
	const { theme } = useContext(ThemeContext)

	const Icon = stat.icon
	const getColour = useCallback((colour: string | undefined, type: "dark" | "main" | "light"): string => {
		if (loading) return "var(--theme-action-loading)"
		if (!colour) colour = defaultColours[(colourIndex || 0) % defaultColours.length]
		let newCol = getShadedOrThemeColour(colour, type, lightenColourShadePercentage(theme.type), darkenColourShadePercentage(theme.type))
		return newCol
	}, [loading])

	let value = loading ? "" : formatNumber(stat.value || 0)

	return (
		<div
			{...others}
			className={clsx("split-label-container", others.className)}
			style={{
				...(others.style || {}),
				"--bg-colour": getColour(stat.colour, "dark"),
				"--text-colour": getColour(stat.colour, theme.type === "light" ? "dark" : "light")
			}}
		>
			{left && <span className={clsx("split-value", {"loader text": loading, "private": !showData, "!w-10": loading || !showData})}>{showData ? value : ""}</span>}
			<div className="split-label-box" ref={ref as LegacyRef<any>}>
				{Icon && <Icon />}
				<span className="split-label">{stat.label}</span>
			</div>
			{!left && <span className={clsx("split-value", {"loader text": loading, "private": !showData, "!w-12": loading  || !showData})}>{showData ? value : ""}</span>}
		</div>
	)
})

export interface SplitStatVisualProps {
	stat: SplitStatStatistic,
	loading?: boolean
}

const SplitStatVisual: React.FC<SplitStatVisualProps> = ({ stat, loading }) => {

	const [containerSize, setContainerSize] = useState<Size>({
		width: 0,
		height: 0
	})
	const [boxSize, setBoxSize] = useState<Size>({
		width: 0,
		height: 0
	})

	let containerRef = useRef<HTMLDivElement>()
	let boxRef = useRef<HTMLDivElement>()

	const onResize = useCallback(() => {
		let container = containerRef.current
		if (!container) return;
		let containerBounds = container.getBoundingClientRect()
		setContainerSize({width: containerBounds.width, height: containerBounds.height})

		let box = boxRef.current
		if (!box) return;
		let boxBounds = box.getBoundingClientRect()
		setBoxSize({width: boxBounds.width, height: boxBounds.height})
	}, [containerRef, stat, boxRef])

	useEffect(() => {
		onResize()
	}, [onResize])

	useEventListener(window, "resize", onResize)

	return (
		<div className="split-stat-root" ref={(el) => containerRef.current = el || undefined}>
			<SplitStatLabel
				className="stat-total-label"
				stat={stat}
				loading={loading}
			/>
			<div className="split-children-container">
				{stat.splits?.map((stat, i) => (
					<SplitStatLabel
						ref={(el) => boxRef.current = el || undefined}
						key={stat.label}
						stat={stat}
						loading={loading}
						colourIndex={i+1}
						left
					/>
				))}
			</div>
			<SplitBackground
				boxSize={boxSize}
				containerSize={containerSize}
				stat={stat}
				loading={loading}
			/>
		</div>
	)
}

export default SplitStatVisual

export interface SplitBackgroundProps {
	stat: SplitStatStatistic
	containerSize: Size,
	boxSize: Size,
	loading?: boolean
}

export const SplitBackground: React.FC<SplitBackgroundProps> = ({ stat, containerSize, boxSize, loading }) => {
	const boxOffset = 40
	const strokeWidth = "2.5rem"
	const overlapWidth = 40
	const length = stat.splits?.length || 0;
	const { theme } = useContext(ThemeContext)

	const getColour = useCallback((colour: string | undefined, type: "dark" | "main" | "light", i?: number): string => {
		if (loading) return "var(--theme-action-loading)"
		if (!colour) colour = defaultColours[(i || 0) % defaultColours.length]
		let newCol = getShadedOrThemeColour(colour, type, lightenColourShadePercentage(theme.type), darkenColourShadePercentage(theme.type))
		return newCol
	}, [loading])

	const totalStartX = boxSize.width / 2
	const totalStartY = containerSize.height / 2

	const containerEnd = containerSize.width - boxSize.width / 2 -10

	const heightMultiplier = (boxSize.height + boxOffset / 2)
	const getHeightIndex = (i: number) => (i - length / 2 + 0.5)

	const curveSize = Math.max(containerSize.width / 2 - boxSize.width, 150)
	const curveSpread = 10
	const curveRating = 80
	const curveStart = containerEnd - curveSize

	const preOverlapTotalEndX = curveStart - overlapWidth

	const opacity = 0.35

	return (
		<svg
			className="split-background"
			viewBox={`0 0 ${containerSize.width} ${containerSize.height}`}
			width="100%" height="100%"
		>
			<defs>
				<linearGradient
					id="total-gradient"
					gradientUnits="userSpaceOnUse"
					x1={preOverlapTotalEndX} x2={preOverlapTotalEndX + overlapWidth}
					y1={totalStartY} y2={totalStartY}
				>
					<stop offset="0" stopOpacity={1} stopColor={getColour(stat.colour, "main")} />
					<stop offset="1" stopOpacity={0} stopColor={getColour(stat.colour, "main")} />
				</linearGradient>
				{stat.splits?.map((currentStat, i) => (
					<linearGradient
						key={i}
						id={`gradient-${i}`}
						gradientUnits="userSpaceOnUse"
						x1={preOverlapTotalEndX} x2={preOverlapTotalEndX + overlapWidth}
						y1={totalStartY} y2={totalStartY}
					>
						
						<stop offset="0" stopOpacity={0} stopColor={getColour(currentStat.colour, "main",i+1)} />
						<stop offset="1" stopOpacity={1} stopColor={getColour(currentStat.colour, "main",i+1)} />
					</linearGradient>
				))}
			</defs>
			
			<path
				style={{strokeWidth, stroke: getColour(stat.colour, "main"), fill: "none", opacity: opacity}}
				d={`M ${totalStartX},${totalStartY} H ${preOverlapTotalEndX}`}
			/>
			
			<path
				className="overlap-path"
				style={{strokeWidth, stroke: `url(#total-gradient)`, fill: "none",opacity:opacity}}
				d={
					`M ${preOverlapTotalEndX},${totalStartY}
					h ${overlapWidth}`
				}
				
			/>
			{stat.splits?.map((currentStat, i) => (
				<React.Fragment key={i}>
					<path
						className="overlap-path"
						style={{
							strokeWidth,
							opacity: opacity,
							stroke: `url(#gradient-${i})`,
						}}
						d={
							`M ${preOverlapTotalEndX},${totalStartY}
							h ${overlapWidth}`
						}
						
					/>
					<path
						style={{
							strokeWidth,
							stroke: getColour(currentStat.colour, "main",i+1),
							fill: "none",
							opacity: opacity
						}}
						d={`M ${curveStart},${totalStartY}
							c ${curveRating + curveSpread},0
							0,${getHeightIndex(i) * (heightMultiplier + curveSpread)},
							${curveSize},${getHeightIndex(i) * (heightMultiplier)}
						`}/>
				</React.Fragment>
				)
			)}
		</svg>
	)

}