import clsx from "clsx"
import { Properties } from "csstype"
import React, { MutableRefObject, SyntheticEvent, useRef } from "react"
import { ComponentItem } from "../../types/Components"
import { useClickAway } from "../../util"

import "./Dropdown.css"

export interface DropdownProps {
	items: ComponentItem[],
	onClick: (item: ComponentItem, e: SyntheticEvent) => void,
	onClose: () => void,
	renderFunction?: (item: ComponentItem) => JSX.Element | JSX.Element[],
	open: boolean,
	containerRef: MutableRefObject<HTMLElement | null | undefined>,
	itemStyle?: (value: string | null) => Properties
}

const Dropdown: React.FC<DropdownProps> = ({
	items,
	onClick,
	onClose,
	renderFunction = (item: ComponentItem) => item.label,
	open,
	containerRef,
	itemStyle
}) => {
	const ref = useRef<HTMLElement | null | undefined>()

	useClickAway(ref, () => onClose(), [containerRef])
	return (
		<div className={clsx("dropdown", {open})} ref={(el) => ref.current = el}>
			{items.map((item: ComponentItem) => (
				<button
					style={itemStyle?.(item.value) || {}}
					key={item.value}
					onClick={
						(e: SyntheticEvent) => onClick(item, e)
					}
				>
					{renderFunction(item)}
				</button>
			))}
		</div>
	)
}

export default Dropdown