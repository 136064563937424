export const roundSensibly = (num: number) => {
	return Math.round(num * 10000) / 10000
}

export const minMax = (num: number, min: number, max: number) => {
	if (num < min) return min;
	if (num > max) return max;
	return num;
}

export const numberWithCommas = (num: number | string): string => {
    var parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}


export const formatNumber = (num: number): string => {
	return numberWithCommas(roundToDecimalPlaces(num, 2));
}

export const roundToDecimalPlaces = (num: number, numPlaces: number): string => {
	let numStr = num.toString();
	let split = numStr.split(".")
	let newNumStr = split[0]
	let decimalStr = split[1]
	if (decimalStr) {
		newNumStr += "."
		for (let i = 0; i < numPlaces; i++) {
			let currDecimal = decimalStr.length >= i + 1 ? decimalStr[i] : "0"
			newNumStr += currDecimal
		}
	} else {
		return newNumStr
	}
	return newNumStr
}

export const isNumber = (str: string): boolean => {
	if (str === "") return false;
	return str.replace(/-?[0-9]+(\.[0-9]+)?/, "") === ""
}

export const toSignificantFigures = (num: number, sigFigures: number): string => {
	let nonDecimals = num.toString().split(".")[0]
	let nonDecimalDigits = nonDecimals.length
	if (nonDecimals === "0") nonDecimalDigits = 0
	
	let roundedNum = Math.floor(num * Math.pow(10, sigFigures - nonDecimalDigits)) / Math.pow(10, sigFigures - nonDecimalDigits)
	nonDecimals = roundedNum.toString().split(".")[0]
	nonDecimalDigits = nonDecimals.length
	if (nonDecimals === "0") nonDecimalDigits = 0
	return roundedNum.toFixed(Math.max(sigFigures - nonDecimalDigits, 1))
}

export const zeroPad = (num: number | string, zeros: number): string => {
	let numStr = num.toString()
	let split = numStr.split(".")
	if (num === 0) return "0";
	if (split.length === 1) {
		let newStr = split[0]
		for (let i = 0; i < zeros - newStr.length; i++) {
			numStr = `0${numStr}`
		}
	} else {
		let nonDecimalLength = split[0] === "0" ? 0 : split[0].length
		let newStr = split[1]
		for (let i = 0; i < zeros - newStr.length - nonDecimalLength; i++) {
			numStr = `${numStr}0`
		}
	}
	return numStr
}