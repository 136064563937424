export type DayFilter = "1" | "7" | "30"

export const dateRanges = [
	"1 day",
	"1 week",
	"1 month",
	"3 months",
	"6 months",
	"1 year",
	"all time",
	"custom"
] as const
export type DateRange = (typeof dateRanges)[number]

export const dateUnits = [
	"day",
	"week",
	"month",
	"year",
	"auto"
] as const
export type DateUnit = (typeof dateUnits)[number]

export const getNumberFromRange = (unit: DateUnit, range: DateRange): number => {
	const mult = {
		"auto": 1,
		"day": 1,
		"week": 1/7,
		"month": 1/12,
		"year": 1/365
	}[unit]

	return Math.ceil({
		"1 day": 1,
		"1 week": 7,
		"1 month": 30,
		"3 months": 3 * 30,
		"6 months": 6 * 30,
		"1 year": 365,
		"all time": 365,
		"custom": 30
	}[range] * mult)
}

export type DateArgs<Unit extends boolean = true> = Unit extends true ? {
	startDate: string,
	endDate?: string,
	unit: DateUnit
} : {
	startDate: string,
	endDate?: string,
}
export interface APIError {
	code: number,
	message?: string
}

export interface UserData {
	id: string,
	email: string,
	name: string,
	role: string,
	isEmailVerified: boolean,
	requiresPasswordChange: boolean
}

export interface TokenItem {
	token: string,
	expires: string
}

export interface TokensData {
	access?: TokenItem,
	refresh?: TokenItem
}

export interface LoginResponse {
	user: UserData,
	tokens: TokensData
}

export type DataResponse<T> = {
	dashboards: T,
	swaps: T
}

export type DashboardsResponse = string[]
export type SwapsResponse = string[]

export type RegistrationsResponse = DataResponse<{
	[key: string]: {
		date: string,
		registrations: number
	}[]
}>

export type TransactionsResponse = DataResponse<{
	[key: string]: {
		date: string,
		transactions: number,
		amount: number | null
	}[]
}>

export type SummaryResponse = DataResponse<{
	[key: string]: {
		total_amount: number,
		total_users: number
	}[]
}>

export type CountriesResponse = DataResponse<{
	[key: string]: {
		nationality: string,
		users: number,
		amount: number
	}[]
}>

export type CurrenciesResponse = DataResponse<{
	[key: string]: {
		currency: string,
		transactions: number,
		amount: number
	}[]
}>

export type DepositsResponse = DataResponse<{
	[key: string]: {
		additional_deposits: number,
		first_deposits: number,
		second_deposits: number
	}[]
}>