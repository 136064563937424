import clsx from "clsx"
import React from "react"

import "./CircularProgressIndicator.css"


export interface CircularProgressIndicatorProps {
	fraction: number,
	size?: number
	className?: string,
	colour?: string,
	noTrack?: boolean
}

const CircularProgressIndicator: React.FC<CircularProgressIndicatorProps> = ({
	fraction,
	className,
	size = 20,
	colour,
	noTrack = false
}) => {
	const radius = 40
	const circumference = Math.PI * 2 * radius

	if (fraction < 0) fraction = 0
	if (fraction > 1) fraction = 1

	const percent = ((1 - fraction) * circumference)

	const strokeWidth = "0.3125rem"

	return (
		<div className={clsx("circular-progress", className)}>
			<svg style={{height: `${size / 4}rem`, width: `${(size / 4)}rem`}} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
				{!noTrack && <circle
					className="track" style={{strokeWidth}}
					r={radius} cx="50" cy="50"
					fill="transparent"
				></circle>}
				<circle
					className="filled" style={{strokeWidth, color: colour}}
					id="bar" r={radius} cx="50"
					cy="50" fill="transparent" strokeDasharray="251.4"
					strokeDashoffset={`${percent}px`}
				></circle>
			</svg>
		</div>
	)
}

export default CircularProgressIndicator