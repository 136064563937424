import React, { SyntheticEvent, useRef, useState } from "react"
import IconButton from "../IconButton"
import InputBase from "../InputBase"

import { Properties } from "csstype"

import "./Select.css"

import { ReactComponent as ArrowDropdownIcon } from "../../svg/icons/arrow-dropdown.svg"
import { ReactComponent as CheckBoxEmptyIcon } from "../../svg/icons/check-box-empty.svg"
import { ReactComponent as CheckBoxIcon } from "../../svg/icons/check-box.svg"
import { ReactComponent as CloseIcon } from "../../svg/icons/close.svg"

import Chip from "../Chip"
import Dropdown from "../Dropdown"
import { ComponentItem } from "../../types/Components"
import clsx from "clsx"



export type SelectProps = {
	label: string,
	multiple: true,
	onChange: (totalValues: any[], newValue: any, prevValues: any[]) => void,
	items: ComponentItem[],
	value: any[],
	emptyValueChip?: JSX.Element | string,
	itemStyle?: (value: string | null) => Properties,
	isSelected?: (value: string | null, selected: string[]) => boolean
} | {
	label: string,
	multiple?: false,
	onChange: (newValue: any) => void,
	items: ComponentItem[],
	value: any,
	emptyValueChip?: undefined,
	itemStyle?: (value: string | null) => Properties
	isSelected?: undefined
}

const Select: React.FC<SelectProps> = ({
	label,
	items,
	multiple,
	value,
	onChange,
	emptyValueChip,
	itemStyle,
	isSelected = (value, selected) => selected.includes(value || "")
}) => {
	const [ dropdownOpen, setDropdownOpen ] = useState(false)

	const containerRef = useRef<HTMLDivElement | null>()

	const valueComponent = multiple ? (items.filter((item) => value.includes(item.value)) || [] as ComponentItem[])
		.map((item: ComponentItem) => (
		<Chip key={item.value} style={itemStyle?.(item.value) || {}}>
			{item.label}
			<IconButton size={18} icon={CloseIcon} onClick={(e: SyntheticEvent) => {
				if (multiple) {
					e.stopPropagation()
					let newValue = [...value]
					let index = newValue.findIndex((value) => value === item.value)
					if (index > -1) {
						newValue.splice(index, 1)
					} else {
						newValue.push(item.value)
					}
					return onChange(newValue, item.value, value)
				}
			}} />
		</Chip>
	)) : <p style={itemStyle?.(value)}>{items.find((item: ComponentItem) => item.value === value)?.label || ""}</p>
	if (multiple && (valueComponent as []).length === 0 && emptyValueChip) {
		(valueComponent as (JSX.Element | string)[]).push(<Chip className="empty-value" key={"EMPTY VALUE"} style={itemStyle?.(null)}>
			{emptyValueChip}
		</Chip>)
	}

	return (
		<InputBase
			ref={(el) => containerRef.current = el}
			label={label}
			className={clsx("select", {multiple})}
			labelFixed={multiple ? value.length > 0 || emptyValueChip !== undefined : value !== undefined}
			selected={dropdownOpen}
			onClick={() => setDropdownOpen(!dropdownOpen)}
		>
			<div className="value-container thin-scroll">
				{valueComponent}
			</div>
			<ArrowDropdownIcon className="dropdown-icon" />
			<Dropdown
				open={dropdownOpen}
				items={items}
				onClose={() => setDropdownOpen(false)}
				containerRef={containerRef}
				renderFunction={(item: ComponentItem) => (
					<>
						{item.label}
						{multiple && (isSelected(item.value, value) ?
							<CheckBoxIcon /> : <CheckBoxEmptyIcon />)
						}
					</>
				)}
				itemStyle={itemStyle}
				onClick={(item, e: SyntheticEvent) => {
					if (multiple) e.stopPropagation()
					if (multiple) {
						let newValue = [...value]
						let index = newValue.findIndex((value) => value === item.value)
						if (index > -1) {
							newValue.splice(index, 1)
						} else {
							newValue.push(item.value)
						}
						return onChange(newValue, item.value, value)
					}
					onChange(item.value)
				}}
			/>
		</InputBase>
	)
}

export default Select