import React from "react"
import Card, { CardBody, CardTitle } from "../Card"

import "./FormPage.css"

export interface FormPageProps {
	title: string
}

const FormPage: React.FC<FormPageProps> = ({ title, children }) => {
	return (
		<div className="form-page">
			<div className="form-bg" style={{backgroundImage: "url(image/background/waves.svg)"}}/>
			<Card className="form-wrapper">
				<CardTitle title={title} />
				<CardBody className="form-container">
					{children}
				</CardBody>
			</Card>
		</div>
	)
}

export default FormPage