import clsx from "clsx"
import React from "react"

import { ReactComponent as ChangeIcon } from "../../svg/icons/arrow-up.svg"
import { capitalize } from "../../util"
import { ToggleButton, ToggleGroup } from "../ToggleGroup"

import "./Card.css"

export type CardProps = {
	className?: string,
	component?: React.ElementType,
} & React.HTMLAttributes<HTMLDivElement>

const Card: React.FC<CardProps> = ({ children, className, component, ...others }) => {
	const Component = component || "div"
	return (
		<Component {...others} className={clsx("card", className)}>
			{children}
		</Component>
	)
}

export default Card

export type CardTitleProps = {
	center?: boolean;
	title?: string;
	figure?: string;
	figureChange?: number;
	filtered?: boolean;
	filterValues?: string[];
	filterSelected?: string;
	onFilterChange?: (newFilterSelected: string) => void;
	titleClass?: string;
	figureClass?: string;
} & React.HTMLAttributes<HTMLDivElement>

export const CardTitle: React.FC<CardTitleProps> = ({
	title,
	center = false,
	figure,
	figureChange,
	children,
	filtered,
	filterValues,
	filterSelected,
	onFilterChange,
	titleClass,
	figureClass,
	...others
}) => {
	return (
		<div {...others} className={clsx("card-title", {"text-center": center, positive: (figureChange || 0) >= 0, negative: (figureChange || 0) < 0})}>
			<div className="card-title-text-container">
				{title !== undefined && <h1 className={clsx(titleClass)}>{title}</h1>}
				{figure !== undefined && (
					<div className="figure-container">
						<h2 className={clsx("figure-label", figureClass)}>{figure}</h2>
						{figureChange && (
							<div className="change-container">
								<ChangeIcon className="change-icon" />
								<span className="change">{`${figureChange > 0 ? "+" : ""}${Math.floor(figureChange * 10000) / 100}%`}</span>
							</div>
						)}
					</div>
				)}
			</div>
			{filtered && (
				<ToggleGroup
					className="card-buttons"
					items={(filterValues || []).map((valueKey) => ({label: capitalize(valueKey), value: valueKey}))}
					value={filterSelected}
					onChange={(newSelected: string) => onFilterChange?.(newSelected)}
				>
					{(filterValues || []).map((valueKey) => (
						<ToggleButton key={valueKey} value={valueKey} />
					))}
				</ToggleGroup>
			)}
			{children}
		</div>
	)
}

export interface CardBodyProps {
	className?: string;
	component?: React.ElementType;
	padding?: number
}

export const CardBody: React.FC<CardBodyProps> = ({ className, component, children, padding }) => {
	const Component = component || "div"
	return (
		<Component className={clsx("card-body", className)} style={padding !== undefined ? {padding: `${padding}rem`} : {}}>
			{children}
		</Component>
	)
}