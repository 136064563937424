import clsx from "clsx"
import React from "react"

import "./Switch.css"

export type SwitchProps = {
	on: boolean,
	onChange: (newOn: boolean) => void
	[key: string]: any
}

const Switch: React.FC<SwitchProps> = ({ on, onChange, ...others }) => {
	return (
		<div
			{...others}
			className={clsx("switch-container", {on})}
			onClick={() => onChange(!on)}
		>
			<div className="switch-track">
				<div className="switch-thumb" />
			</div>
		</div>
	)
}

export default Switch