import clsx from "clsx"
import React, { useContext } from "react"

import Card, { CardBody, CardTitle } from "../../components/Card"
import { ThemeContext } from "../../context/ThemeContext"

import themes from "../../styles/themes"
import { Theme } from "../../styles/themes/baseTheme"
import RadioButton from "../RadioButton"

import "./ThemeSetting.css"

const ThemeSetting: React.FC = () => {
	const { setTheme, theme } = useContext(ThemeContext)

	return (
		<Card className="theme-setting">
			<CardTitle title="Themes" />
			<CardBody className="theme-preview-grid">
				{Object.entries(themes).map(([key, currentTheme], i) => (
					<ThemePreviewItem
						key={i}
						theme={currentTheme}
						onSelect={() => setTheme(currentTheme.key)}
						selected={theme.key === currentTheme.key}
					/>
				))}
			</CardBody>
		</Card>
	)
}

export interface ThemePreviewItemProps {
	theme: Theme,
	onSelect: () => void,
	selected: boolean
}

export const ThemePreviewItem: React.FC<ThemePreviewItemProps> = ({ theme, selected, onSelect }) => {
	return (
		<Card className="theme-preview" onClick={() => onSelect()}>
			<CardBody>
				<div className="theme-bg" style={{backgroundColor: theme.background?.default}}>
					<div className="theme-card" style={{backgroundColor: theme.background?.paper, boxShadow: theme.shadows?.card}}>
						<div className="theme-card-header theme-text" style={{backgroundColor: theme.text?.primary}} />
						<div className="theme-list" style={{"--selected-col": theme.action?.hover}}>
							{[false, true, false].map((isSelected, i) => (
								<div className={clsx("theme-list-item", {selected: isSelected})} key={i}>
									<div className="theme-list-indicator" style={{backgroundColor: theme.text?.muted}}></div>
									<div className="theme-text" style={{backgroundColor: theme.text?.muted}} />
								</div>
							))}
						</div>
					</div>
					<div className="theme-primary-button" style={{backgroundColor: theme.primary?.main, boxShadow: theme.elevations?.[2]}} />
				</div>
			</CardBody>
			<CardTitle style={{backgroundColor: theme.background?.paper, color: theme.text?.primary}}>
				<RadioButton selected={selected} />
				{theme.label}
			</CardTitle>
		</Card>
	)
}

export default ThemeSetting