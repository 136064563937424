import React, { useContext } from "react"
import { CountriesContext } from "../../context/CountriesContext"
import { formatNumber } from "../../util"
import TopFilteredCard from "../TopFilteredCard"

import "./CountriesChart.css"

const CountriesChart: React.FC = () => {
	const { chartDataSorted, loading } = useContext(CountriesContext)

	return (
		<TopFilteredCard
			title="Top Countries"
			chartDataSorted={chartDataSorted}
			mainKey="nationality"
			valueKeys={["users", "amount"]}
			valueTransformers={{amount: (amount: number) => `$${formatNumber(amount)}`}}
			loading={loading}
		/>
	)
}

export default CountriesChart