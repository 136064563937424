import React, { useContext } from "react"
import CountriesChart from "../../components/CountriesChart"
import CurrenciesChart from "../../components/CurrenciesChart"
import FilterBar from "../../components/FilterBar"
import TransactionsGraph from "../../components/TransactionsGraph"
import UsersChart from "../../components/RegistrationsChart"

import "./Home.css"
import UsersStat from "../../components/UsersStat"
import CardGroup from "../../components/CardGroup"
import Page from "../../components/Page"
import BestPerformingTokensFilteredCard from "../../components/BestPerformingTokensFilteredCard"
import WorstPerformingTokensFilteredCard from "../../components/WorstPerformingTokensFilteredCard"
import DepositsStat from "../../components/DepositsStat"
import { AuthContext } from "../../context/AuthContext"

const Home: React.FC = () => {
	const { user } = useContext(AuthContext)

	return (
		<Page className="home-page">
			<CardGroup>
				<UsersStat />
				<DepositsStat />
			</CardGroup>
			<CardGroup>
				<UsersChart />
				<TransactionsGraph />
			</CardGroup>
			{ user && user.role !== "customer" && <>
				<CardGroup>
					<CountriesChart />
					<CurrenciesChart />
				</CardGroup>
				<CardGroup>
					<BestPerformingTokensFilteredCard />
					<WorstPerformingTokensFilteredCard />
				</CardGroup>
			</>}
		</Page>
	)
}

export default Home