import React, { createContext, useContext, useEffect } from "react"
import { RegistrationsResponse } from "../types/Api"
import { useRegistrationsRequest } from "../util"
import { AuthContext } from "./AuthContext"
import { FilterContext } from "./FilterContext"

export const RegistrationsContext = createContext<RegistrationsContextData>({
	registrations: {
		swaps: {},
		dashboards: {}
	},
	loading: true
})

export interface RegistrationsContextData {
	registrations: RegistrationsResponse,
	loading: boolean
}

export const RegistrationsContextWrapper: React.FC = ({ children }) => {
	const registrationsRequest = useRegistrationsRequest()
	const { loggedIn, user, tokensFetchedAt } = useContext(AuthContext)
	const { dateUnit, startDate, endDate } = useContext(FilterContext)

	useEffect(() => {
		if (!loggedIn || !user?.isEmailVerified || user?.requiresPasswordChange) return
		registrationsRequest.sendRequest({
			startDate,
			endDate,
			unit: dateUnit
		})
	}, [loggedIn, dateUnit, startDate, endDate, tokensFetchedAt, user?.isEmailVerified, user?.requiresPasswordChange])

	// useInterval(() => {
	// 	registrationsRequest.sendRequest(days)
	// }, 10000)

	const RegistrationsData: RegistrationsContextData = {
		registrations: registrationsRequest.data || {swaps: {}, dashboards: {}},
		loading: (registrationsRequest.dataHistory.length === 0 || !registrationsRequest.data) && (registrationsRequest.fetching || !registrationsRequest.fetchedAt)
	}

	return (
		<RegistrationsContext.Provider value={RegistrationsData}>
			{children}
		</RegistrationsContext.Provider>
	)
}