import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Layout from "./pages/Layout"
import Home from "./pages/Home"
import Reports from "./pages/Reports"
import Login from './pages/Login';
import { GlobalContextWrapper } from './context/GlobalContext';
import VerifyEmail from './components/VerifyEmail';
import ChangePassword from './pages/ChangePassword';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import SettingsPage from './pages/SettingsPage';

const App = () => {
	return (
		<BrowserRouter>
			<GlobalContextWrapper>
				<Layout>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/login" element={<Login />} />
						<Route path="/reports" element={<Reports />} />
						<Route path="/settings" element={<SettingsPage />} />
						<Route path="/verify-email" element={<VerifyEmail />} />
						<Route path="/change-password" element={<ChangePassword />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/reset-password" element={<ResetPassword />} />
					</Routes>
				</Layout>
			</GlobalContextWrapper>
		</BrowserRouter>
	);
}

export default App;
