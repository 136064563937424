import React, { useContext, useState } from "react"
import { Navigate } from "react-router-dom"
import Button from "../../components/Button"
import Card, { CardBody } from "../../components/Card"
import Input from "../../components/Input"
import { AlertContext } from "../../context/AlertContext"
import { AuthContext } from "../../context/AuthContext"
import { isFalse, useChangePasswordRequest } from "../../util"

import "./ChangePassword.css"

const ChangePassword: React.FC = () => {
	const changePasswordRequest = useChangePasswordRequest()
	const { addAlert } = useContext(AlertContext)
	const { updateUser, loggedIn, user } = useContext(AuthContext)
	const [ currentPassword, setCurrentPassword ] = useState("")
	const [ newPassword, setNewPassword ] = useState("")

	const changePassword = () => {
		changePasswordRequest.sendRequest(currentPassword, newPassword)
		.then(() => {
			addAlert({label: "Successfully changed password", type: "success", duration: 4000})
			updateUser({requiresPasswordChange: false})
		}).catch((err) => {
			addAlert({label: `Error: ${err.message}`, type: "error", duration: 4000})
		})
	}
	

	if (changePasswordRequest.success || user?.requiresPasswordChange === false) return <Navigate to="/" />

	if (isFalse(loggedIn)) return <Navigate to="/login" />

	return (
		<div className="verify-email-page">
			<div className="verify-email-container">
				<h1>Change Password</h1>
				<Card className="verify-form">
					<CardBody component="form">
						<Input
							id="current-password"
							label="Current Password"
							autoComplete="current-password"
							value={currentPassword}
							onChange={(e) => setCurrentPassword(e.target.value)}
							type="password"
						/>
						<Input
							id="new-password"
							label="New Password"
							autoComplete="new-password"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
							type="password"
						/>
						<div className="buttons-container">
							<Button
								color="primary"
								loading={changePasswordRequest.fetching}
								onClick={(e) => {
									e.preventDefault()
									if (changePasswordRequest.fetching) return
									changePassword()
								}}
							>
								Change Password
							</Button>
						</div>
					</CardBody>
				</Card>
			</div>
		</div>
	)
}

export default ChangePassword