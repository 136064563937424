import React, { useContext, useLayoutEffect, useState } from "react"

import "./Navigation.css"

import dayjs from "dayjs"

import clsx from "clsx"
import { NavigationContext } from "../../context/NavigationContext"
import { NavLink } from "react-router-dom"

import Select from "../Select"
import { capitalize, remove } from "../../util"
import { DashboardsContext } from "../../context/DashboardsContext"
import { FilterContext } from "../../context/FilterContext"
import { ComponentItem } from "../../types/Components"

import { ReactComponent as DashboardIcon } from "../../svg/icons/dashboard.svg"
import { ReactComponent as ReportIcon } from "../../svg/icons/report.svg"
import { ReactComponent as SettingsIcon } from "../../svg/icons/settings.svg"
import { ReactComponent as LogoutIcon } from "../../svg/icons/logout.svg"
import Button from "../Button"
import { AuthContext } from "../../context/AuthContext"
import { SwapContext } from "../../context/SwapContext"
import { dateRanges, dateUnits } from "../../types/Api"
import Input from "../Input"

const navItems: {
	label: string,
	path: string,
	icon: React.ElementType
}[] = [
	{label: "Dashboard", path: "/", icon: DashboardIcon},
	{label: "Reports", path: "/reports", icon: ReportIcon},
	{label: "Settings", path: "/settings", icon: SettingsIcon},
]

const dateRangeItems: ComponentItem[] = dateRanges.map((dateRange) => {
	return {value: dateRange, label: capitalize(dateRange)}
})

const dateUnitItems: ComponentItem[] = dateUnits.map((dateUnit) => {
	return {value: dateUnit, label: capitalize(dateUnit)}
})

const Navigation: React.FC = () => {
	(window as any).dayjs = dayjs
	const { logout, user } = useContext(AuthContext)
	const { navigationOpen, setNavigationOpen } = useContext(NavigationContext)
	const { dashboards, colours: dashboardColours } = useContext(DashboardsContext)
	const { swaps, colours: swapColours } = useContext(SwapContext)
	const {
		selectedDashboards,
		setSelectedDashboards,
		selectedSwaps,
		setSelectedSwaps,
		dateRange, setDateRange,
		dateUnit, setDateUnit,
		customDateValue, setCustomDateValue
	} = useContext(FilterContext)

	const [ currentStartDateStr, setCurrentStartDateStr ] = useState("")
	const [ currentEndDateStr, setCurrentEndDateStr ] = useState("")

	useLayoutEffect(() => {
		setCurrentStartDateStr(dayjs(customDateValue[0]).format("DD/MM/YYYY"))
		setCurrentEndDateStr(dayjs(customDateValue[1]).format("DD/MM/YYYY"))
	}, [customDateValue])

	const onStartDateEnter = (e: React.FocusEvent<HTMLInputElement, Element> | React.KeyboardEvent<HTMLInputElement>) => {
		setCustomDateValue((oldValue) => {
			let newVal = dayjs(e.currentTarget.value, "DD/MM/YYYY")
			let newStr = ""
			if (newVal.add(1, "day").isAfter(oldValue[1])) {
				newStr = dayjs(oldValue[1]).subtract(1, "day").toISOString()
			} else newStr = newVal.toISOString()
			console.log(newStr)

			return [newStr, oldValue[1]]
		})
	}

	const onEndDateEnter = (e: React.FocusEvent<HTMLInputElement, Element> | React.KeyboardEvent<HTMLInputElement>) => setCustomDateValue((oldValue) => {
		let newVal = dayjs(e.currentTarget.value, "DD/MM/YYYY")
		let newStr = ""
		if (newVal.subtract(1, "day").isBefore(oldValue[0])) {
			newStr = dayjs(oldValue[0]).add(1, "day").toISOString()
		} else newStr = newVal.toISOString()

		return [oldValue[0], newStr]
	})

	return (
		<>
			<nav className={clsx({open: navigationOpen})}>
				<div className="nav-filters">
					<Select
						label="Dashboard Tokens"
						items={["All Tokens", "None", ...(dashboards || [])].map(
							(v: string) => ({label: capitalize(v), value: v})
						)}
						multiple
						onChange={(totalValues: any[], newValue, prevValues) => {
							if (newValue === "All Tokens") {
								if (prevValues.includes("All Tokens")) return
								return setSelectedDashboards([])
							}
							if (newValue === "None") {
								return setSelectedDashboards(totalValues.filter((val) => val === "None"))
							}
							setSelectedDashboards(remove(totalValues, "None"))
						}}
						isSelected={(value, selected) => {
							if (value === "All Tokens") return selected.length === 0
							if (value === "None") return selected.includes("None")
							return selected.includes(value || "")
						}}
						value={selectedDashboards}
						emptyValueChip={"All Tokens"}
						itemStyle={(value: string | null) => ({color: value === null ? "" : dashboardColours[value], fontWeight: "bold"})}
					/>
					{user?.role && user?.role !== "customer" && (
						<Select
							label="Swap Tokens"
							items={["All Tokens", "None", ...(swaps || [])].map(
								(v: string) => ({label: capitalize(v), value: v})
							)}
							multiple
							onChange={(totalValues: any[], newValue, prevValues) => {
								if (newValue === "All Tokens") {
									if (prevValues.includes("All Tokens")) return
									return setSelectedSwaps([])
								}
								if (newValue === "None") {
									return setSelectedSwaps(totalValues.filter((val) => val === "None"))
								}
								setSelectedSwaps(remove(totalValues, "None"))
							}}
							isSelected={(value, selected) => {
								if (value === "All Tokens") return selected.length === 0
								if (value === "None") return selected.includes("None")
								return selected.includes(value || "")
							}}
							value={selectedSwaps}
							emptyValueChip={"All Tokens"}
							itemStyle={(value: string | null) => ({color: value === null ? "" : swapColours[value], fontWeight: "bold"})}
						/>
					)}
					<Select
						label="Date Range"
						items={dateRangeItems}
						onChange={(newRange: any) => setDateRange(newRange)}
						value={dateRange}
					/>
					<Select
						label="Date Unit"
						items={dateUnitItems}
						onChange={(newUnit: any) => setDateUnit(newUnit)}
						value={dateUnit}
					/>
					{
						dateRange === "custom" && (
							<>
								<Input
									labelFixed
									label="Start Date"
									id="start-date"
									placeholder="DD/MM/YYYY"
									value={currentStartDateStr}
									onChange={(e) => setCurrentStartDateStr(e.target.value)}
									onBlur={onStartDateEnter}
									onKeyDown={(e) => e.key === "Enter" && onStartDateEnter(e)}
								/>
								<Input
									labelFixed
									label="End Date"
									id="end-date"
									placeholder="DD/MM/YYYY"
									value={currentEndDateStr}
									onChange={(e) => setCurrentEndDateStr(e.target.value)}
									onBlur={onEndDateEnter}
									onKeyDown={(e) => e.key === "Enter" && onEndDateEnter(e)}
								/>
							</>
						)
					}
				</div>
				<ul className="nav-list">
					{navItems.map((navItem) => (
						<li className="nav-item" key={navItem.path}>
							<NavLink to={navItem.path} onClick={() => setNavigationOpen(false)}>
								{React.createElement(navItem.icon)}
								{navItem.label}
							</NavLink>
						</li>
					))}
				</ul>
				<div className="nav-bottom">
					<Button onClick={() => logout()}>
						<LogoutIcon />
						Logout
					</Button>
				</div>
			</nav>
			<div
				className={clsx("nav-overlay", {open: navigationOpen})}
				onClick={() => {
					setNavigationOpen(false)
				}}
			/>
		</>
	)
}

export default Navigation