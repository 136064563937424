import clsx from "clsx"
import React from "react"

import "./TableSkeleton.css"

export interface TableSkeletonProps {
	columns?: number,
	rows?: number
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({ columns = 3, rows = 12 }) => {
	const isInLastCol = (itemIndex: number): boolean => {
		return itemIndex % columns === columns - 1
	}

	const isInLastRow = (rowIndex: number): boolean => {
		return rowIndex === rows -1
	}

	return (
		<div className="custom-table skeleton" style={{"--table-size-columns": columns}}>
			{new Array(columns).fill(0).map((_, i) => (
				<div
					key={i}
					className={
						clsx(
							"custom-table-item","custom-table-head",
							{"in-last-col": isInLastCol(i), "in-last-row": rows === 0}
						)
					}
				>
					<p className="loader text" />
				</div>
			))}
			{new Array(rows).fill(0).map((_, rowIndex) => (
				<React.Fragment key={rowIndex}>
					{new Array(columns).fill(0).map((_, columnIndex) => (
						<div
							className={
								clsx(
									"custom-table-item",
									{"in-last-col": isInLastCol(columnIndex),
									"in-last-row": isInLastRow(rowIndex)}
								)}
							key={rowIndex + " - " + columnIndex}
						
						>
							<p className="loader text"></p>
						</div>
					))}
				</React.Fragment>
			))}
		</div>
	)
}

export default TableSkeleton