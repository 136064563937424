import { AxiosError, AxiosResponse } from "axios"
import React, { createContext, MutableRefObject, useEffect, useRef } from "react"
import { useNavigate } from "react-router"
import { TokensData, UserData } from "../types/Api"
import { useGetUserRequest, useLocalState, useLocalStateRef, useRefreshTokensRequest } from "../util"

export const AuthContext = createContext<AuthContextData>({
	tokens: {},
	tokensRef: {current: {}},
	user: null,
	loggedIn: false,
	setTokens: () => {},
	login: () => {},
	logout: () => {},
	refreshTokens: () => Promise.reject(),
	tokensFetchedAt: 0,
	updateUser: () => {}
})

export interface AuthContextData {
	tokens: TokensData,
	tokensRef: MutableRefObject<TokensData>,
	user: UserData | null,
	loggedIn: boolean,
	setTokens: (newTokens: TokensData) => void,
	login: (user: UserData, tokens: TokensData) => void,
	logout: () => void,
	refreshTokens: () => Promise<AxiosResponse | AxiosError>,
	tokensFetchedAt: number,
	updateUser: (newUserProps: Partial<UserData>) => void,
}

export const AuthContextWrapper: React.FC = ({ children }) => {
	const navigate = useNavigate()
	const refreshTokensRequest = useRefreshTokensRequest()
	const [ tokens, setTokens, tokensRef ] = useLocalStateRef<TokensData>({}, "tokens")
	const [ user, setUser ] = useLocalState<UserData | null>(null, "user")
	const [ loggedIn, setLoggedIn ] = useLocalState<boolean>(false, "loggedIn")
	const refreshedUserRef = useRef(false)
	
	const getUserRequest = useGetUserRequest(tokensRef)

	const refreshingRef = useRef(false)
	const refreshingPromiseRef = useRef<Promise<any> | null>(null)

	const logout = () => {
		setLoggedIn(false)
		setUser(null)
		navigate("/login", {replace: true})
	}

	if (user && (user.isEmailVerified === undefined || user.requiresPasswordChange === undefined)) logout()

	useEffect(() => {
		if (refreshedUserRef.current) return
		if (!loggedIn || !user?.id || !tokens.access) return
		refreshedUserRef.current = true
		getUserRequest.sendRequest(user.id)
			.then((res) => {
				setUser((res as AxiosResponse).data as UserData)
			}).catch((err) => {
				if (err.message === "Email must be verified" || err.message === "Password change required") return
				logout()
			})
	}, [loggedIn, user])

	const AuthData: AuthContextData = {
		tokens, tokensRef, user, loggedIn, tokensFetchedAt: refreshTokensRequest.fetchedAt,
		setTokens,
		updateUser: (newUserProps: Partial<UserData>) => {
			if (user) {
				setUser({...user, ...newUserProps} as UserData)
			}
		},
		login: (newUser: UserData, tokens: TokensData) => {
			setLoggedIn(true)
			setUser(newUser)
			setTokens(tokens)
		},
		logout,
		refreshTokens: (): Promise<any> => {
			if (refreshingRef.current && refreshingPromiseRef.current !== null){
				return refreshingPromiseRef.current;
			}
			refreshingPromiseRef.current = new Promise((resolve, reject) => {
				refreshingRef.current = true
				if (!tokens.refresh?.token || Date.now() > new Date(tokens.refresh?.expires).getTime()) {
					AuthData.logout()
					console.log("LOGGING OUT DUE TO EXPIRED REFRESH")
					return Promise.reject();
				}
				refreshTokensRequest.sendRequest(tokens.refresh?.token)
					.then((res: AxiosResponse<TokensData, any> | AxiosError) => {
						res = res as AxiosResponse<TokensData, any>
						setTokens(res.data as TokensData)
						resolve(res)
					})
					.catch((err) => {
						AuthData.logout()
						console.error(err)
						reject(err)
					})
					.finally(() => {
						refreshingRef.current = false
						refreshingPromiseRef.current = null
					})
			})
			return refreshingPromiseRef.current
		}
	}

	return (
		<AuthContext.Provider value={AuthData}>
			{children}
		</AuthContext.Provider>
	)
}