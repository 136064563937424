import React, { useContext } from "react"
import { DepositsContext } from "../../context/DepositsContext"
import { FilterContext } from "../../context/FilterContext"
import { extractSplitStatData, filterAndCombine, filterTokens, formatNumber, getDataTotals } from "../../util"
import Card, { CardBody, CardTitle } from "../Card"
import SplitStatVisual from "../SplitStatVisual"

import { ReactComponent as TotalIcon } from "../../svg/icons/leaderboard.svg"
import { ReactComponent as FirstIcon } from "../../svg/icons/first.svg"
import { ReactComponent as SecondIcon } from "../../svg/icons/second.svg"
import { ReactComponent as ThirdPlusIcon } from "../../svg/icons/third-plus.svg"

import "./DepositsStat.css"
import SplitStatCard from "../SplitStatCard"

const DepositsStat: React.FC = () => {
	const { deposits, loading } = useContext(DepositsContext)
	const { totalSelected, showData } = useContext(FilterContext)

	const labelIconMap: Record<string, [string, string | undefined, React.ElementType | undefined]> = {
		total: ["Total", "primary", TotalIcon],
		first_deposits: ["First", undefined, FirstIcon],
		second_deposits: ["Second", undefined, SecondIcon],
		additional_deposits: ["Additional", undefined, ThirdPlusIcon],
	}
	const totals = getDataTotals(filterAndCombine(deposits, totalSelected), ["additional_deposits", "first_deposits", "second_deposits"])
	const splitData = extractSplitStatData(totals, labelIconMap)

	return (
		<SplitStatCard
			title="Deposits"
			loading={loading}
			stat={splitData}
			suffix="Deposits"
		/>
	)
}

export default DepositsStat