import React, { createContext, useContext, useEffect } from "react"
import { SummaryResponse } from "../types/Api";
import { useSummaryRequest } from "../util";
import { AuthContext } from "./AuthContext";

const defaultValue = {
	summary: {
		swaps: {},
		dashboards: {}
	},
	loading: true
}

export const SummaryContext = createContext<SummaryContextData>(defaultValue)

export interface SummaryContextData {
	summary: SummaryResponse,
	loading: boolean
}

export const SummaryContextWrapper: React.FC = ({ children }) => {
	const summaryRequest = useSummaryRequest()
	const { loggedIn, user } = useContext(AuthContext)

	useEffect(() => {
		if (!loggedIn || !user?.isEmailVerified || user?.requiresPasswordChange) return
		summaryRequest.sendRequest()
	}, [loggedIn, user?.isEmailVerified, user?.requiresPasswordChange])

	const SummaryData: SummaryContextData = {
		summary: summaryRequest.data || defaultValue.summary,
		loading: summaryRequest.fetching || !summaryRequest.fetchedAt
	}

	return (
		<SummaryContext.Provider value={SummaryData}>
			{children}
		</SummaryContext.Provider>
	)
}