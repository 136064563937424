import React, { useContext, useState } from "react"
import { FilterContext } from "../../context/FilterContext"
import { TransactionsContext } from "../../context/TransactionsContext"
import { extractChartData, filterAndCombine, filterTokens, formatNumber, getCombinedTotalKeys, getDataTotal, omitKeys } from "../../util"
import { BarGraphCard } from "../GraphCard"

import "./TransactionsGraph.css"

const TransactionsGraph: React.FC = () => {
	const { transactions, loading } = useContext(TransactionsContext)
	const { totalDashboards, totalSwaps, totalSelected } = useContext(FilterContext)
	const [ selectedType, setSelectedType ] = useState("transactions")

	const filteredTransactions = filterAndCombine(transactions || {}, totalSelected)
	const total = (selectedType === "amount" ? "$" : "") + formatNumber(getDataTotal(filteredTransactions, selectedType))
	const chartData = extractChartData(filteredTransactions, selectedType, "date")
	const payload = extractChartData(filteredTransactions, ["transactions", "amount"], "date")
	
	return (
		<BarGraphCard
			xKey="date"
			className="transactions-graph"
			data={chartData}
			keys={{dashboards: totalDashboards, swaps: totalSwaps}}
			title="Transactions"
			figureText={total}
			filtered
			filterValues={["transactions", "amount"]}
			filterSelected={selectedType}
			onFilterChange={(newFilter: string) => setSelectedType(newFilter)}
			payload={payload}
			formatter={(value: number[], name: string, dataItem: Record<string, any>) => {
				if (!value) return undefined
				let values: number[] = value
				if (name.toLowerCase() === "total") {
					let total = [0]
					let payloadItem = payload.find((item) => item.date === dataItem.payload.date)
					if (payloadItem) Object.entries(payloadItem).forEach(([key, payloadValue]) => {
						if (Array.isArray(payloadValue)) payloadValue.forEach((val, i) => {
							total[i] = (total[i] || 0) + val
						})
					})
					values = total
				} else {
					values = (payload.find((item) => item.date === dataItem.payload.date) || {[name]: value})[name] as number[] || value
				}

				return formatNumber(values[0] || 0) + " - $" + formatNumber(values[1] || 0)
			}}
			loading={loading}
		/>
	)
}

export default TransactionsGraph