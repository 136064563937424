import clsx from "clsx"
import React from "react"

import "./RadioButton.css"

export interface RadioButtonProps {
	selected: boolean
}

const RadioButton: React.FC<RadioButtonProps> = ({ selected }) => {
	return (
		<div className={clsx("radio-button-container", {selected})}>
			<div className="radio-button-circle" />
		</div>
	)
}

export default RadioButton