import clsx from "clsx"
import { Properties } from "csstype";
import React from "react"

import "./Chip.css"

export interface ChipProps {
	className?: string;
	style?: Properties
}

const Chip: React.FC<ChipProps> = ({ className, children, style }) => {
	return (
		<div className={clsx("chip", className)} style={style}>
			{children}
		</div>
	)
}

export default Chip