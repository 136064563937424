import clsx from "clsx"
import React from "react"

import "./CardGroup.css"

export interface CardGroupProps {
	className?: string
}

const CardGroup: React.FC<CardGroupProps> = ({ className, children }) => {
	if (!children || (Array.isArray(children) && children.length === 0)) return <></>;
	return (
		<section className={clsx("card-group", className)}>
			{children}
		</section>
	)
}

export default CardGroup