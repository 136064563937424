import React from "react"
import { SplitStatStatistic } from "../components/SplitStatVisual"
import { DashboardsResponse, DataResponse, UserData } from "../types/Api"
import { isNumber } from "./number"

export const serializeValue = (value: any): string => {
	if (typeof(value) === "number") return JSON.stringify({
		__serialized: true,
		__type: "number",
		value
	})
	if (typeof(value) === "string") return JSON.stringify({
		__serialized: true,
		__type: "string",
		value
	})
	if (typeof(value) === "boolean") return JSON.stringify({
		__serialized: true,
		__type: "bool",
		value
	})
	return JSON.stringify(value)
}

export const deserializeValue = (serializedValue: string): any => {
	let parsed = JSON.parse(serializedValue)
	if (!parsed) return null
	if (parsed.__serialized === true) {
		if (parsed.__type === "number") return Number.parseFloat(parsed.value)
		if (parsed.__type === "boolean") return parsed.value
		return parsed.value.toString()
	}
	return parsed
}

const isPrime = (n: number): boolean => {
   if (n===1) return false;
   else if(n === 2) return true;
   else {
      for(let x = 2; x < n; x++){
         if(n % x === 0) return false;
      }
      return true;
   };
};

const getNearestPrime = (num: number): number => {
   while(!isPrime(++num)){};
   return num;
};

export const alternateArray = <T>(arr: T[], multiple: number = 2): T[] => {
	let newArr = new Array(arr.length)

	const addToArray = (value: T, index: number) => {
		if (!newArr[index]) newArr[index] = value
		else newArr[index + 1 % arr.length] = value
	}

	arr.forEach((v, i) => {
		addToArray(v, i * multiple % arr.length)
		newArr[i*multiple % arr.length] = v
	})
	
	return newArr
}

export const alternateArrayNoLoop = <T>(arr: T[], multiple: number = 2): T[] => {
	let arrCopy: T[] = [...arr]
	let arrs: T[][] = new Array(multiple).fill(0).map(() => {
		return arrCopy.splice(0, Math.ceil(arr.length / multiple))
	})

	let finalArr: T[] = []

	arrs[0].forEach((_, i) => {
		arrs.forEach((currArr) => {
			if (currArr[i] !== undefined) finalArr.push(currArr[i])
		})
	})

	return finalArr
}

export const getQueryVariable = (variable: string): string | null=> {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
	return null;
}

export const isTrue = (val: any): boolean => {
	if (val === "true") return true;
	if (val === true) return true;
	return false;
}

export const isFalse = (val: any): boolean => {
	if (typeof(val) === "string" && val.trim() === "false") return true;
	if (val === false) return true;
	if (val === undefined || val === null) return true
	return false;
}

export const getKeyObjArr = (
	data: Record<string, string[]>
): {key: string, category: string}[] => {
	let newData: {key: string, category: string}[] = []
	Object.entries(data).forEach(([key, value]) => {
		value.forEach((currVal) => {
			newData.push({key: currVal, category: key})
		})
	})
	return newData;
}

export const omitKeys = <T>(obj: Record<string, T>, keysToOmit: string[]): Record<string, T> => {
	let newObj: Record<string, T> = {}
	Object.entries(obj).forEach(([key, value]) => {
		if (keysToOmit.includes(key)) return;
		newObj[key] = value 
	})
	return newObj;
}

export const remove = <T>(arr: T[], objToRemove: T): T[] => {
	return arr.filter((item) => item !== objToRemove)
}

export const isAuthenticatedUser = (user: UserData | null): boolean => {
	if (!user) return false;
	return isTrue(user.isEmailVerified) && isFalse(user.requiresPasswordChange)
}

export const extractSplitStatData = (
	totalsData: Record<string, number>,
	infoMap: Record<string, [string, string | undefined, React.ElementType | undefined]>,
): SplitStatStatistic => {
	let total = 0;
	let splits: SplitStatStatistic[] = []
	let keys = Object.keys(infoMap).filter((key) => key !== "total")

	keys.forEach((key) => {
		let value = totalsData[key]
		splits.push({
			label: infoMap[key][0],
			colour: infoMap[key][1],
			icon: infoMap[key][2],
			value,
		})
		total += value;
	})

	let totalMap = infoMap["total"] || ["Total", "primary", undefined]

	let totalStat: SplitStatStatistic = {
		label: totalMap[0],
		colour: totalMap[1],
		icon: totalMap[2],
		value: total,
		splits
	}

	return totalStat;
}