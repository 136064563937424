import clsx from "clsx"
import React, { useContext, useEffect, useState } from "react"
import { DashboardsContext } from "../../context/DashboardsContext"
import { FilterContext } from "../../context/FilterContext"
import { capitalize, formatNumber, getDataTotalFromExtracted, toSignificantFigures } from "../../util"
import Card, { CardBody, CardTitle } from "../Card"
import TopFilteredCardSkeleton from "../TopFilteredCardSkeleton"

import { ReactComponent as ChevronLeft } from "../../svg/icons/chevron-left.svg"
import { ReactComponent as ChevronRight } from "../../svg/icons/chevron-right.svg"

import "./TopFilteredCard.css"
import IconButton from "../IconButton"

export interface TopFilteredCardProps {
	className?: string,
	title: string,
	valueKeys: string[],
	mainKey: string,
	chartItemsLength?: number,
	topItemsLength?: number,
	chartDataSorted: Record<string, Record<string, any>[]>,
	keyTransformer?: (key: string) => string,
	valueTransformers?: Record<string, (value: number) => string>,
	loading?: boolean,
	filterSelected?: string,
	onFilterChange?: (newFilterSelected: string) => void,
	reverse?: boolean
}

const TopFilteredCard: React.FC<TopFilteredCardProps> = ({
	title,
	valueKeys,
	mainKey,
	chartDataSorted,
	keyTransformer = (key) => key,
	chartItemsLength = 12,
	topItemsLength = 5,
	valueTransformers = {},
	className,
	loading = false,
	onFilterChange,
	filterSelected,
	reverse = false
}) => {
	const { showData } = useContext(FilterContext)
	const { colourArr, colours } = useContext(DashboardsContext)

	const [ selectedDataItemValue, setSelectedDataItemValue ] = useState<string>()
	const [ clickedDataItemValue, setClickedDataItemValue ] = useState<string>()
	const [ selectedKey, setSelectedKey ] = useState<string>(valueKeys[0])
	const [ pageNumber, setPageNumber ] = useState(0)

	const selectedChartData = chartDataSorted[selectedKey] || []

	useEffect(() => {
		if (clickedDataItemValue !== undefined || selectedDataItemValue !== undefined || selectedChartData.length <= 1) return
		setClickedDataItemValue(selectedChartData[0][mainKey])
		setSelectedDataItemValue(selectedChartData[0][mainKey])
	}, [selectedChartData])

	useEffect(() => {
		if (!clickedDataItemValue) return
		const selectedIndex = selectedChartData.findIndex((dataItem) => dataItem[mainKey] === clickedDataItemValue)
		const selectedDataItem = selectedChartData[selectedIndex]
		if (selectedDataItem || !selectedChartData || selectedChartData.length === 0) return
		setClickedDataItemValue(selectedChartData[0][mainKey])
		setSelectedDataItemValue(selectedChartData[0][mainKey])
	}, [selectedChartData])

	

	// let remainingDataItems = [...selectedChartData].splice(chartItemsLength - 1)
	
	// let otherTotals: Record<string, number> = {}
	// remainingDataItems.forEach((countryVal, i) => {
	// 	Object.entries(countryVal).forEach(([key, value]) => {
	// 		if (otherTotals[countryVal[key]] === undefined) otherTotals[countryVal[key]] = 0
	// 		otherTotals[countryVal[key]] += value
	// 	})
	// })

	let totals: Record<string, number> = {}
	valueKeys.forEach((valueKey) => {
		totals[valueKey] = Object.entries(chartDataSorted).length > 0 ? getDataTotalFromExtracted(Object.entries(chartDataSorted)[0][1], valueKey) : 0
	})
	const totalSelected = totals[selectedKey]
	const topData = [...(reverse ? [...selectedChartData].reverse() : selectedChartData)].splice(pageNumber * topItemsLength, topItemsLength)
	let topDataTotal = 0
	topData.forEach((dataItem) => topDataTotal += dataItem[selectedKey])

	const selectedIndex = selectedChartData.findIndex((dataItem) => dataItem[mainKey] === selectedDataItemValue)
	const selectedDataItem = selectedChartData[selectedIndex] || {};

	if (loading) return <TopFilteredCardSkeleton topItemsLength={topItemsLength} />

	return (
		<Card className={clsx("card-filtered", className)}>
			<CardTitle
				title={title}
				filtered
				filterValues={valueKeys}
				filterSelected={filterSelected !== undefined ? filterSelected : selectedKey}
				onFilterChange={(newSelected: string) => {
					onFilterChange?.(newSelected)
					setSelectedKey(newSelected)
					setPageNumber(0)
				}}
			/>
			<CardBody>
				<div className="filtered-data-container">
					<div className="filtered-list" style={{minHeight: `${4.125 * topItemsLength}rem`}}>
						{topData.map((dataItem, i) => (
							<div
								className={clsx(
									"filtered-list-item",
									{
										active: dataItem[mainKey] === selectedDataItemValue,
										selected: dataItem[mainKey] === clickedDataItemValue
									}
								)}
								key={i}
								onMouseEnter={() => setSelectedDataItemValue(dataItem[mainKey])}
								onMouseLeave={() => setSelectedDataItemValue(clickedDataItemValue)}
								onClick={() => {
									setClickedDataItemValue(dataItem[mainKey])
									setSelectedDataItemValue(dataItem[mainKey])
								}}
							>
								<p className="label">{keyTransformer(dataItem[mainKey] || "Unknown")}</p>
								<div className="filtered-bar-container">
									<div
										className="filtered-bar"
										style={{
											width: `calc(${(dataItem[selectedKey] * 100 / selectedChartData[0][selectedKey]) || 0} * (1% - 0.04rem))`,
											backgroundColor: colours[dataItem[mainKey]] ? colours[dataItem[mainKey]] : colourArr[i % colourArr.length]
										}}
									/>
									<span
										className="filtered-bar-label"
									>{toSignificantFigures((dataItem[selectedKey] * 100 / totalSelected) || 0, 2)}%</span>
								</div>
							</div>
						))}
					</div>
					<div className="pagination-buttons">
						<IconButton
							icon={ChevronLeft}
							disabled={pageNumber - 1 < 0}
							onClick={() => setPageNumber((pageNum) => pageNum - 1)}
						/>
						{pageNumber + 1}
						<IconButton
							icon={ChevronRight}
							disabled={(pageNumber + 1) >= Math.ceil(selectedChartData.length / topItemsLength)}
							onClick={() => setPageNumber((pageNum) => pageNum + 1)}
						/>
					</div>
				</div>
				<div className="filtered-hover-data">
					<div className="filtered-title-container">
						<h2 className="filtered-title">
							{keyTransformer(selectedDataItemValue || "Unknown")}
						</h2>
						<p className="filtered-title-percent" style={{color: colourArr[selectedIndex % colourArr.length]}}>
							{toSignificantFigures((selectedDataItem[selectedKey] * 100 / totalSelected || 0), 2) || 0}%
						</p>
					</div>
					<div className="filtered-hover-list">
						{valueKeys.map((valueKey) => (
							<div className="filtered-hover-list-item" key={valueKey}>
								<h3 className="title">{capitalize(valueKey)}</h3>
								<p className={clsx("value", {"private": !showData, "!w-[4em]": !showData})}>
									{
										showData ? (valueTransformers[valueKey] !== undefined ?
											valueTransformers[valueKey](selectedDataItem[valueKey] || 0) :
											formatNumber(selectedDataItem[valueKey] || 0)) : ""
									}
								</p>
							</div>
						))}
					</div>
				</div>
			</CardBody>
		</Card>
	)
}

export default TopFilteredCard