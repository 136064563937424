import React, { createContext } from "react"
import themes from "../styles/themes"
import type { Theme } from "../styles/themes/baseTheme"
import baseTheme from "../styles/themes/baseTheme"
import { useLocalState } from "../util"

export const ThemeContext = createContext<ThemeContextData>({
	theme: baseTheme,
	setTheme: () => {}
})

export interface ThemeContextData {
	theme: Theme,
	setTheme: (themeKey: string) => void,
}

export const ThemeContextWrapper: React.FC = ({ children }) => {
	const [ themeKey, setThemeKey ] = useLocalState("blue", "theme")

	const ThemeData: ThemeContextData = {
		theme: themes[themeKey],
		setTheme: setThemeKey
	}

	return (
		<ThemeContext.Provider value={ThemeData}>
			{children}
		</ThemeContext.Provider>
	)
}