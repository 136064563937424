import React, { useContext } from "react"
import { ThemeContext } from "../../context/ThemeContext"
import Card, { CardBody, CardTitle } from "../Card"
import CircularProgressIndicator from "../CircularProgressIndicator"

import "./PercentageStatCardSkeleton.css"

const PercentageStatCardSkeleton: React.FC = () => {
	const { theme } = useContext(ThemeContext)

	return (
		<Card className="percentage-stat-card skeleton">
			<CardTitle title="" titleClass="loader text" />
			<CardBody>
				<div className="circle-container">
					<CircularProgressIndicator
						size={40}
						fraction={1}
						colour={theme.action?.loading}
						noTrack
					/>
					<div className="progress-label-container">
						<p className="progress-total loader text !w-[4em]" />
						<p className="progress-total-label loader text !w-[5em]" />
					</div>
				</div>
				<div className="percentage-stats-container">
					<div className="percentage-stats-item part">
						<p className="title loader text" />
						<p className="figure loader text" />
					</div>
					<div className="percentage-stats-item not-part">
						<p className="title loader text" />
						<p className="figure loader text" />
					</div>
				</div>
			</CardBody>
		</Card>
	)
}

export default PercentageStatCardSkeleton