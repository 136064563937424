import React, { useContext } from "react"
import { CurrenciesContext } from "../../context/CurrenciesContext"
import { formatNumber } from "../../util"
import TopFilteredCard from "../TopFilteredCard"

import "./CurrenciesChart.css"

const CurrenciesChart: React.FC = () => {
	const { chartDataSorted, loading } = useContext(CurrenciesContext)

	return (
		<TopFilteredCard
			chartDataSorted={chartDataSorted}
			mainKey="currency"
			valueKeys={["transactions", "amount"]}
			title="Popular Currencies"
			keyTransformer={(key: string) => key.toUpperCase()}
			valueTransformers={{amount: (amount: number) => `$${formatNumber(amount)}`}}
			loading={loading}
		/>
	)
}

export default CurrenciesChart